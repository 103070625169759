section.settings h3{
    display:flex;
    font-size:30px;
    /* margin-bottom:20px; */
    width:70%;
    margin:20px auto;
}

section.settings > div{
    display:flex;
    flex-direction:column;
    border:3px solid white;
    height:100%;
    width:70%;
    margin:auto;
    padding:2%;
    /* align-items:center; */
    border-radius:10px;
}

section.settings div.profile,
section.settings div.password,
section.settings div.pin
{
    border:1px solid #a49bac;
    padding:10px 25px;
    margin-bottom:5px;
    
}

section.settings div.profile .header,
section.settings div.password .header,
section.settings div.pin .header{
    position: relative;
    display:flex;
    /* justify-content: space-between; */
}

section.settings div.profile .header .fa,
section.settings div.password .header .fa,
section.settings div.pin .header .fa{
    position: absolute;
    right:0;
}

section.settings div.profile .header .fa:hover,
section.settings div.password .header .fa:hover,
section.settings div.pin .header .fa:hover{
    color:#9a49af;
    cursor:pointer;
}


section.settings div.profile .header .fa-chevron-up,
section.settings div.password .header .fa-chevron-up,
section.settings div.pin .header .fa-chevron-up{
    display:none;
}

section.settings div.profile .form,
section.settings div.password .form,
section.settings div.pin .form{
    display:none;
    margin-top:20px;
    /* display:flex; */
    flex-direction:column;
    justify-content:center;
    align-items:center;
    
    
}

section.settings div.profile .show-form,
section.settings div.password .show-form,
section.settings div.pin .show-form{
    display:flex;
  
    animation:show-form .6s ease-in-out;
   
   
}

@keyframes show-form{
    from{
        opacity:0;
    }

    to{
        opacity:1;
        height:50%;
    }
}

section.settings div.profile .form form div,
section.settings div.password .form form div,
section.settings div.pin .form form div{
    margin:15px 0;
    display:flex;
    flex-direction:column;
}

section.settings div.profile .form form div label,
section.settings div.password .form form div label,
section.settings div.pin .form form div label{
    margin-bottom:10px;
    font-size:14px;
    color:#93879f;
}
section.settings div.profile .form form div input,
section.settings div.password .form form div input,
section.settings div.pin .form form div input{
    background: transparent;
    color:white;
    border:1px solid #93879f;
    padding:10px 15px;
    border-radius:15px;
    font-size:14px;
    outline:none;
}
section.settings div.profile .form form div{
    position:relative;
}
section.settings div.profile .form form div i{
    color:white;
    position: absolute;
    right: 10px;
    top: 58%;
    cursor:pointer;
}
section.settings div.profile .form form div p.copy-success-message{
    color:white;
    position: absolute;
    right: 10px;
    font-size:11px;
    display:none;
    
    /* top: 58%; */
    cursor:pointer;
}
section.settings div.profile .form form div i:hover{
    color:#93879f;
    
}
section.settings div.pin .form form  p{
    text-align:center;
    display:block;
}
section.settings div.pin .form  h4,
section.settings div.password .form  h4{
    /* color:pink; */
    font-size:15px;
    text-align:center;
    width:50%;
}
section.settings div.password .form form div h6,
section.settings div.pin .form form div h6{
    margin-top:5px;
    color:#93879f;
}

section.settings div.password .form p,
section.settings div.pin .form p{
    background-color: #b856a9;
    padding:10px 15px;
    border-radius: 5px;;
}


section.settings div.password .form p:hover,
section.settings div.pin .form p:hover{
    cursor: pointer;
    transform: scale(0.95);
}



