section.airtime > div{
    
    border:3px solid white;
    height:100%;
    width:70%;
    margin:auto;
    padding:5%;
    align-items:center;
    border-radius:10px;
}
section.airtime > div > div >div{
    display: flex;
    flex-direction:column;
    align-items:center;
}

section.airtime div.header{
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 60%;
    /* background: #43275f; */
    /* padding: 20px 0 */
}
section.airtime div.header h4{
    font-size:20px;
    padding: 10px 10px;
    cursor: pointer
}
section.airtime h5{
    text-align: center;
}
section.airtime h3{
    display:flex;
    font-size:30px;
    /* margin-bottom:20px; */
    width:70%;
    margin:20px auto;
}

section.airtime  div.header h4.left-active{
    background: #2c1d3a;
    /* padding: 10px 20px; */
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

section.airtime  div.header h4.right-active{
    background: #2c1d3a;
   
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
section.airtime  div.modal-right{
    display: none;
}

section.airtime form{
    display: flex;
    flex-direction: column;
    margin:auto;
    margin-bottom:10px;
    /* justify-content: center; */
    /* align-items: center; */
    width: 60%;
    background: #2c1d3a;
    /* margin: 0; */
    padding: 10px 20px;
}

section.airtime form div{
    display:flex;
    flex-direction: column;
    margin:15px 0;
}

section.airtime form div:nth-of-type(6){
    flex-direction: row;
    align-items:center;
}

section.airtime form div:nth-of-type(6) label{
    margin:0 10px;
}

section.airtime form div:nth-of-type(6) input{
    background:#1e0338;
}
section.airtime form div label{
    margin-bottom:10px;
}

section.airtime form div select option{
    color: white;
    background:#1e0338;
    border:none;
    outline:none;
    padding:3px 0;
}

section.airtime form div select option:hover{
    background: brown;
}


section.airtime form div input,
section.airtime form div select{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #7e3eb5;
}

section.airtime form div select{
    color: -internal-light-dark(black, white) ;

}

section.airtime form div.check p{
    text-align: center;
    padding:5px 5px;
    background:#9548b0;
    width:10%;
    height:30px;
    /* align-items:ce nter; */
}

section.airtime form div.check p .fa{
    display:none;
}

section.airtime form div.check p:hover{
    transform:none
}


section.airtime p{
    text-align: center;
    padding:10px 10px;
    background:#2c1d3a;
    width:20%;
    cursor:pointer;
    margin-bottom:5px;
}

section.airtime p:hover{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}