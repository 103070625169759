
/* SIDEBAR */

section.admin-sidebar{
    position: fixed;
    background:#1e0338;
    top:70px;
    bottom:0;
    left:0;
    border-right:1px solid white;
    display: flex;
    flex-direction: column;
    /* align-items:center; */
    /* text-align:center; */
    padding:15px 10px;
    font-size: 13px;;
    color:#8e819b;
    width:5%;
    /* height:100vh; */
    /* transition: all 1s ease-in-out; */
    
}


section.maximize-sidebar{
    transition: all 0.5s ease-in-out;
    width: 11%;
    border-right:1px solid #7339b8;
}

section.admin-sidebar .direction{
   
    justify-content:flex-end;
    margin:0;
    
    
    border-radius:0;
}



section.admin-sidebar .direction .fa{
font-size:30px;
/* position: absolute; */
top:10px;
right:5px;
}

section.admin-sidebar .direction .fa-chevron-circle-left{
    display: none;
}


section.admin-sidebar .direction  .show-chev{
    display:block;
}

section.admin-sidebar .direction .fa:hover{
    color: #9748b0;
    cursor:pointer;
    
}
section.admin-sidebar p{
    
    margin-left: 7px;;
}


section.admin-sidebar div p.description{
    /* transition: all 1s ease-in-out; */

    /* transition: all 0.5s ease-in; */
    display: none;
}

section.admin-sidebar div p.slowly-show-description{
    display: flex;
        opacity: 1; 
        /* margin-left: 500px; */
    animation: slowly-show 0.5s ease-in;
}

@keyframes slowly-show{
    0%{
        display: none;
        opacity: 0;
        
        
    }

    50%{
        display: none;
        opacity: 0.5;
       

    }
    100%{
        display: flex;
        opacity: 1; 
        
    }
}


section.admin-sidebar div.overview:hover,
section.admin-sidebar div.user:hover,
section.admin-sidebar div.transactions-div:hover,
section.admin-sidebar div.payments:hover,
section.admin-sidebar div.data:hover,
section.admin-sidebar div.cable-tv:hover,
section.admin-sidebar div.settings:hover{
    background: #9748b0;
    cursor:pointer;
    color:white;
    
   
}

section.admin-sidebar div.logout:nth-child(2):hover{
    background: red;
    cursor:pointer;
    color:white;
}

.side-bar-active{
    background: #9748b0;
    cursor:pointer;
    color:white;
    
}


section.admin-sidebar div{
    
    margin:10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px 7px;
    border-radius: 10px;;
    
}

section.admin-sidebar div{
    align-self: center;
    width: 100%;
}



section.admin-sidebar .sidebar-footer{
    padding:0;
    flex-direction: column;
    margin-top:120px;
}

section.admin-sidebar .sidebar-footer div:hover{
    background-color: white;
    cursor:pointer;
}

