

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
section.faqs div.image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/c_scale,h_669/v1607619150/faqs_wq7iuo.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* overflow-y: scroll; */
}

section.faqs div.image-overlay{
    background-color: #1e0338a1;
    position: absolute;
    z-index: 1;
    height: 250px;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 100px;
}
/* section.faqs div.image-overlay h1{
    display:flex;
} */


section.faqs div.write-up{
    background: #ffebcdbf;
    /* height: 100vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 100px 80px;
}

section.faqs div.write-up h1{
    font-size: 30px;
    margin-bottom:20px;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Poppins', sans-serif  ;
}
section.faqs div.write-up div{
    margin-bottom:30px;
    border-bottom:1px solid white;
    padding:30px 0;
}
section.faqs div.write-up p{
 margin-top:10px;
 color: #1b092c;
 font-family: 'Poppins', sans-serif  ;
 /* font-family: 'Raleway', sans-serif; */
}
section.faqs div.write-up p i{
    margin-right:15px;
}

@keyframes animate-from-right{
    from{
        opacity: 0;
        transform:translateX(100px);
    }
    to{
        opacity: 1;
        transform:translateX(0);
    }
}

@keyframes animate-from-left{
    from{
        opacity: 0;
        transform:translateX(-100px);
    }
    to{
        opacity: 1;
        transform:translateX(0);
    }
}


