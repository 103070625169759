div.tdetails{
    border:1px solid white;
    height:90%;
    width:50%;
    /* padding: 10px; */
    display:flex;
    flex-direction:column;
    justify-content:normal;
    align-items:center;
    overflow-y: scroll;
}


div.tdetails .right p{
    padding:10px 10px;
    background: #8240b4;
    font-size:16px;
    margin:10px 0;
    text-align:center;
    /* border-radius: 10px; */
}
div.tdetails .right p.show-on-mobile{
    display:none;
}
div.tdetails .left{
    display: flex;
    
}

div.tdetails .left p{
    padding:15px 15px;
    background: #8240b4;
    font-size:16px;
    margin:10px 10px;
    text-align:center;
    border-radius:5px;
    cursor:pointer;
}

div.tdetails .left p{
    background: #f85d5d;
    text-transform:uppercase;
}