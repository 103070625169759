section.sign-up-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/v1606016777/get-started1_yhyuyd.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y:scroll;
}

section.sign-up-page h1{
    margin-bottom: 30px;
   text-align:center;
    font-size:35px;
    color:white;
}

section.sign-up-page > div{
    /* background:#3a1b58; */
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background:#000000ed;
}

section.sign-up-page > div h3{
    font-size:15px;
    margin-bottom:10px;
    text-align: center;
}

section.sign-up-page > div form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

section.sign-up-page > div form div{
    display: flex;
    align-items: center;
    border:1px solid #5d3b86;
 
    border-radius:5px;
    padding: 10px 15px;
    margin:5px 0 5px 0;
    position:relative;
    width:80%;
}

section.sign-up-page > div form h6{
    margin-bottom:10px;
    color:grey;
    width: 80%;
}

section.sign-up-page > div form div.password,
section.sign-up-page > div form div.key{
    margin:5px 0 5px 0;
}

section.sign-up-page > div form div input{
    background: transparent;
    outline: none;
    border:none;
    padding: 5px 10px;
    width:100%;
    color: white;
}



section.sign-up-page .fa{
    color:#5d3b86;
    margin-right:5px;
}

section.sign-up-page .eye{
    position: absolute;
    right:10px;
}

section.sign-up-page .eye:hover{
    cursor:pointer;
    transition: all 0.3s ease-in-out;
    color:#d764a3;
}

section.sign-up-page .fa-eye-slash {
    display: none;
}

section.sign-up-page > div p{
    margin-top:10px;
    padding:10px 15px;
    background:#5d3b86;
    border-radius: 5px;
    font-size:15px;
}

section.sign-up-page > div p:hover{
    cursor:pointer;
    transition: all 0.5s ease-in;
    background-color: #6f42a5;
}   

section.sign-up-page > div h6 a{
    color:#d764a3;
    text-decoration-line: none;
}

section.sign-up-page > div h6.sign-in{
    margin-top:30px;
}