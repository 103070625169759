div.payment-details{
    border:1px solid white;
    height:70%;
    width:40%;
    padding: 20px;
    display:flex;
    text-align-last: center;
    flex-direction:column;
    justify-content:normal;
    align-items:center;
    overflow-y: scroll;
}


div.payment-details .right p{
    padding:15px 15px;
    background: #8240b4;
    font-size:16px;
    margin:10px 0;
    text-align:center;
}

div.payment-details .left{
    display: flex;
    
}

div.payment-details .left p{
    padding:15px 15px;
    background: #8240b4;
    font-size:16px;
    margin:10px 10px;
    text-align:center;
    border-radius:5px;
    cursor:pointer;
}

div.payment-details .left p:nth-of-type(2){
    background: #f85d5d;

}