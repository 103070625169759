
/* SIDEBAR */

section.sidebar{
    position:fixed;
    background:#1e0338;
    top:70px;
    bottom:0;
    left:0;
    border-right:1px solid white;
    display: flex;
    flex-direction: column;
    /* align-items:center; */
    /* text-align:center; */
    padding:15px 10px;
    font-size: 13px;;
    color:#8e819b;
    width:5%;
    /* height:100%; */
    /* transition: all 1s ease-in-out; */
    /* overflow-y: scroll; */
    
}


section.maximize-sidebar{
    transition: all 0.5s ease-in-out;
    width: 11%;
    border-right:1px solid #7339b8;
}

section.sidebar .direction{
   
    justify-content:flex-end;
    margin:0;
    
    
    border-radius:0;
}



section.sidebar .direction .fa{
font-size:30px;
/* position: absolute; */
top:10px;
right:5px;
}

section.sidebar .direction .fa-chevron-circle-left{
    display: none;
}


section.sidebar .direction  .show-chev{
    display:block;
}

section.sidebar .direction:hover .fa{
    color: #9748b0;
    cursor:pointer;
    
}
section.sidebar p{
    
    margin-left: 7px;;
}


section.sidebar div p.description{
    /* transition: all 1s ease-in-out; */

    /* transition: all 0.5s ease-in; */
    display: none;
}

section.sidebar div p.slowly-show-description{
    display: flex;
        opacity: 1; 
        /* margin-left: 500px; */
    animation: slowly-show 0.5s ease-in;
}

@keyframes slowly-show{
    0%{
        display: none;
        opacity: 0;
        
        
    }

    50%{
        display: none;
        opacity: 0.5;
       

    }
    100%{
        display: flex;
        opacity: 1; 
        
    }
}

section.sidebar .mobile-view-only{
    display:none;
}
section.sidebar .desktop-options{
    position: absolute;
    top:55%;
    /* left:0%; */
    background: #541392;
    left:-100%;
    opacity: 0;
    display:none;
    /* height:60%; */
    /* z-index:1; */
}
section.sidebar .show-options{
    display: block;
    opacity: 1;
    left:0%;
    animation: options 0.5s ease-in
}

@keyframes options{
    from{
        display: none;
        left:-100%;
        opacity: 0;

    }
    to{
        display: block;
        opacity: 1;
        left:0%;
    }
}
section.sidebar div.view-more:hover{
    background: #541392;
    cursor:pointer;
}

section.sidebar div.overview:hover,
section.sidebar div.wallet:hover,
section.sidebar div.transactions-div:hover,
section.sidebar div.data:hover,
section.sidebar div.airtime:hover,
section.sidebar div.cable-tv:hover,
section.sidebar div.electricity:hover,
section.sidebar div.settings:hover,
    section.sidebar div.education:hover,
        section.sidebar div.coupon-code:hover{
    background: #9748b0;
    cursor:pointer;
    color:white;
    
   
}

section.sidebar div.logout:hover{
    background: red;
    cursor:pointer;
    color:white;
}


.side-bar-active{
    background: #9748b0;
    cursor:pointer;
    color:white;
    
}


section.sidebar div{
    
    margin:10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px 7px;
    border-radius: 10px;;
    
}

section.sidebar div{
    align-self: center;
    width: 100%;
}

section.sidebar .services{
    margin-top: 10px;
    padding: 0;
    flex-direction: column;
}

section.sidebar .services h3{
    margin-bottom:10px;
    font-size:18px;
}

section.sidebar .sidebar-footer{
    padding:0;
    flex-direction: column;
    margin-top:7px;
}

