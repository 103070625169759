section.popups-admin{
    position: fixed;
    z-index:5;
    top:0;
    background: white;
    width:100%;
    opacity:0.9;
    /* height:100vh; */
    bottom:0;
    display:none;
    justify-content: center;
    align-items: center;
}

section.show-blurry-pop{
    display:flex;
    background:#000000;
    animation:show-blur 0.5s ease-in;
}


@keyframes show-blur{
    0%{
        opacity: 0;
    }

    50%{
        opacity: 0.3;

    }

    75%{
        opacity: 0.5;

    }

    100%{
        opacity: 0.7;

    }
}

div.slowly-show-popups-admin{
    animation:slowly-show-popups-admin 0.6s ease-in;
}

@keyframes slowly-show-popups-admin{
    0%{
        opacity: 0;
    }

    50%{
        opacity: 0.3;

    }

    75%{
        opacity: 0.7;

    }

    100%{
        opacity: 1;

    }
}