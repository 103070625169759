/* LANDSCAPE  */
@media (max-height:500px){
    section.privacy div.image{
        
        height: 100vh;
        
    }

    
section.privacy div.image-overlay{
  
    height: 100vh;
    
}
}

@media (max-width:270px){
    section.privacy div.image-overlay{
        
        font-size: 60px;
    }
}

@media (max-width:520px){
    section.privacy div.privacy-content{
        
        padding: 100px 50px;
    }
    section.privacy div.image-overlay{
        
        font-size: 70px;
    }

    section.privacy div.image-overlay h1{
        
        font-size: 40px;
    }
}

@media (max-width:768px){
    

    section.privacy div.image-overlay h1{
        
        font-size: 70px;
    }
}
@media (max-width:426px){
    

    section.privacy div.image-overlay h1{
        
        font-size: 40px!important;
    }

    section.privacy div.privacy-content p.intro{
    font-size: 15px;
}

section.privacy div.privacy-content p.question{
    font-size: 14px;
}
section.privacy div.privacy-content p{
    font-size: 11px;
}
}