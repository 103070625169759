div.shared-wallet{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:60px 0;
}



div.shared-wallet .left h3{
    font-size:30px;
    color:#ffa92c;
    border:2px dotted #fc5eac;
    padding:10px;

}

div.shared-wallet .left h5{
    font-size:15px;
    margin-top:10px;

}

div.shared-wallet .left form{
    display: flex;;
    flex-direction: column;
    margin-top:10px;
}

div.shared-wallet .left form div{
    display: flex;;
    flex-direction: column;
    margin:10px 0;
    align-items: baseline;
}
div.shared-wallet .left form div label{
    margin-bottom:5px;
}
div.shared-wallet .left form div input{
    background: #300d42;
    padding:10px 15px;
    color:white;
    border:none;
    outline:none;
}



div.shared-wallet .right p{
    background: #300d42;
    padding: 10px 10px;
    border-radius:10px;
    font-size:15px;
}

div.shared-wallet .right p:hover{
    cursor:pointer;
    transform:scale(0.95) ;

}