section.education h3{
    display:flex;
    font-size:30px;
    /* margin-bottom:20px; */
    width:70%;
    margin:20px auto;
}
section.education h5{
    text-align:center;
}
section.education > div{
    
    border:3px solid white;
    height:100%;
    width:70%;
    margin:auto;
    padding:2%;
    align-items:center;
    border-radius:10px;
}
section.education > div > div >div{
    display: flex;
    flex-direction:column;
    align-items:center;
}
section.education div.header{
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 60%;
    /* background: #43275f; */
    /* padding: 20px 0 */
}
section.education div.header h4{
    font-size:20px;
    padding: 10px 10px;
    cursor: pointer
}
section.education div.header h4.left-active{
    background: #2c1d3a;
    /* padding: 10px 20px; */
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

section.education div.header h4.right-active{
    background: #2c1d3a;
   
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
section.education div.modal-right{
    display: none;
}
section.education form{
    display: flex;
    flex-direction: column;
    margin:auto;
    margin-bottom:10px;
    /* justify-content: center; */
    /* align-items: center; */
    width: 60%;
    background: #2c1d3a;
    /* margin: 0; */
    padding: 10px 20px;
}


section.education form div{
    display:flex;
    flex-direction: column;
    margin:15px 0;
}
section.education form div label{
    margin-bottom:10px;
}

section.education form div select option{
    color: white;
    background:#1e0338;
    border:none;
    outline:none;
    padding:3px 0;
}
section.education form div input,
section.education form div select{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #7e3eb5;
}
section.education p{
    text-align: center;
    padding:10px 10px;
    background:#2c1d3a;
    width:20%;
    cursor:pointer;
    margin-bottom:5px;
}

section.education p:hover{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}
section.education form .units div{
    /* display: flex; */
    flex-direction:row;
    align-items: baseline;
}
section.education form div.units div span{
    margin:0 10px;
}

section.education form div.units div .fa{
    background:#3c3840;
    padding:7px;
    cursor: pointer;
}
section.buy-coupon form div.units div .fa:active{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}