


header.admin-header{
    position:fixed;
    z-index:5;
    background-color:#1e0338;
    right: 0;
    left: 0;
    height:70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:15px 20px;
    border-bottom:1px solid white;
    
}

header.admin-header img.site-logo {
    height: 150px;
    background:transparent;
    /* color: aquamarine; */
}


header.admin-header div.header-right{
    display: flex;
    align-items: center;
    
}

header.admin-header div.header-right div{
    border-color:white;
}
img.profile-picture{
    height: 60px;
    background:white;
    margin-right:10px;
    


}

header.admin-header div.header-right p.username{
    margin-right:50px;
    font-size:13px;
}

header.admin-header div.header-right .fa{
    display:none;
}