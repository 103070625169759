
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
*{
  padding:0;
  margin: 0;
  box-sizing: border-box;
  transition:all 0.2s ease-in-out;
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Poppins', sans-serif  ;

}
*::-moz-selection{
  background:#9E9E9E;
}
*::selection{
  background:#9E9E9E ;
}

body{
  background: #1e0338;
  color:white;
}