div.userdetails{
    border:1px solid white;
    height:60%;
    width:40%;
    padding: 20px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    
}


div.userdetails .right p{
    padding:15px 15px;
    background: #8240b4;
    font-size:16px;
    margin:10px 0;
    text-align:center;
}
div.userdetails .right h4{
    color:pink;
    text-align:center;
}
div.userdetails .left{
    display: flex;
    
}

div.userdetails .left p{
    padding:15px 15px;
    background: #8240b4;
    font-size:16px;
    margin:10px 10px;
    text-align:center;
    border-radius:5px;
    cursor:pointer;
}

div.userdetails .left p:nth-of-type(2){
    background: #f85d5d;

}