div.admin-wallet-balance{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:50px 10px;
}


div.admin-wallet-balance div.value{
     /* flex:2; */
    /* border:10px solid #f9a9c6; */
    border-radius: 100%;
    /* width:40%; */
    /* padding:25px 25px; */
    margin-right:10px;
    color:#d2558f;
    font-size:30px;
   
}

div.admin-wallet-balance div.value h5{
    color:white;
    font-weight: bolder;
}

div.admin-wallet-balance >p{
    font-weight:bold;
    font-size:20px;
}

@media (max-width:650px){
    div.admin-wallet-balance >p{
        
        font-size:15px;
    }
    div.admin-wallet-balance div.value{
       
       font-size:25px;
   }
}