section.admin-overview .grid-wrapper{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding:20px 0;
    /* justify-content:center; */
    width:100%;
    grid-gap: 20px;
}



@media (max-width:480px){
    section.admin-overview .grid-wrapper{
        grid-template-columns: repeat(1,1fr);
        display: block;
        
        
    }
    section.admin-overview .grid-wrapper div{
        margin:10px 0;
    }
}