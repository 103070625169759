@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
*{
    font-family: 'Poppins', sans-serif  ;
}
section.landing-page{
    height: 100vh;
    /* background: white; */
    /* overflow-y:hidden; */

    background:linear-gradient(0deg,#d362a3, #8140b5);
}
section.no-overflow{
    /* overflow-y:hidden; */

}

section.landing-page div.navbar{
    background-color: #ffffff;
    
    
    
}

section.landing-page div.navbar header img.site-logo {
    height: 150px;
    background: transparent;
    
}

section.landing-page div.navbar header{
    display: flex;
    position: fixed;
     left:0;
     right:0;   
     z-index:5;
     padding:0 50px;

    justify-content: space-between;
    height:88px;
    align-items:center;
    /* background-color:transparent; */
    background:linear-gradient(0deg,#d362a3, #8140b5);

    color:#333333;

}

section.landing-page div.navbar header nav{
    display:flex;
    flex-direction:row;
    align-items:center;
    /* justify-content:space-between; */
}
section.landing-page div.navbar header .fa-bars{
    color:white;
    display:none;
}
section.landing-page div.navbar header nav a,
section.landing-page .mobile-navigation a{
    text-decoration:none;
    margin:0 15px;
    /* color:#333333; */
    color:white;
    font-weight:bold;
    font-family: 'Poppins', sans-serif  ;
    /* font-family: 'Open Sans', 'Roboto', 'Helvetica', Sans-Serif; */
    cursor:pointer;

}

section.landing-page div.navbar header nav a:hover,
    section.landing-page .mobile-navigation a:hover{
    transition:all 0.5s ease-in-out;
    /* color:grey; */
    
}

/* section.landing-page div.navbar header nav a:nth-of-type(5),
    section.landing-page .mobile-navigation a:nth-of-type(5){
    padding:10px 25px;
    color:white;
    border-radius:20px;
    background:linear-gradient(0deg, #8140b5, #d362a3);
    cursor:pointer;
} */

/* section.landing-page div.navbar header nav a:nth-of-type(5):hover,
    section.landing-page .mobile-navigation a:nth-of-type(5):hover{
    transform:scale(0.95)
} */
section.landing-page .mobile-navigation{
    position:fixed;
    color:blue;
    background:#8140b5f0;
    z-index:5;
    top:0;
    bottom:0;
    left:0;
    right:0; 
   height:100%;  
   display:none;  
}
section.landing-page .mobile-navigation a:nth-of-type(1){
    animation: flow-in .3s ease forwards;
     opacity:0;
}


section.landing-page .mobile-navigation a:nth-of-type(2){
    animation: flow-in .3s ease forwards .3s;
    opacity:0;
}

section.landing-page .mobile-navigation a:nth-of-type(3){
    animation: flow-in .3s ease forwards .6s;
    opacity:0;
}

section.landing-page .mobile-navigation a:nth-of-type(4){
    animation: flow-in .3s ease forwards .9s;
    opacity:0;
}

section.landing-page .mobile-navigation a:nth-of-type(5){
    animation: flow-in .3s ease forwards 1.12s;
    opacity:0;
}
@keyframes flow-in{
    from{
        opacity:0;
        transform: translate(0%,-500%);
    }
    to{
        opacity:1;
        transform: translate(0%,0%);
    }
}

section.landing-page .get-started{
        position: relative;
        top:80px;
        padding:10px 0;
        /* background-image:url('/images/get-started.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed; */
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        height:100%;
        background:linear-gradient(0deg, #8140b5, #d362a3);
    }
    
section.landing-page .get-started>div{
    position: absolute;
    background:#000000bf;
    width:100%;
    height:100%;
    /* word-break: break-all; */
}
section.landing-page .get-started img{
    height: 100%;
    width:100%;
}
section.landing-page .get-started div div{
    width:60%;
    height:100%;
    
    color:white;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    margin :0 auto;
    text-align:center;
}

section.landing-page .get-started div h1{
    font-size:30px;
    margin-bottom:10px;
    color:#d362a3;
}

section.landing-page .get-started div h3{
    text-align:center;
    /* margin:auto; */
    font-size: 18px;
line-height: 1.5em;
font-family: 'Montserrat', sans-serif;
}


section.landing-page .get-started div a{
    margin-top:50px;
    padding:10px 25px;
    color:white;
    border-radius:20px;
    background:linear-gradient(0deg, #8140b5, #d362a3);
    text-decoration:none;
    transition:all 0.5s ease-in-out;


    
}

section.landing-page .get-started div a:hover{
    
    transform: scale(0.92);
}


section.landing-page .get-started div a{
    
}


@keyframes animate-content{
    from{
        opacity: 0;
        transform:translateY(100px);
    }
    to{
        opacity: 1;
        transform:translateY(0);
    }
}
@keyframes animate-about-us-img{
    from{
        opacity: 0;
        transform:translateX(-100px);
    }
    to{
        opacity: 1;
        transform:translateX(0);
    }
}

section.landing-page .about-us{
    background:linear-gradient(0deg, #8140b5, #d362a3);
    padding:20px 30px;
    position:relative;
    top:60px;
    height:100%;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    align-items:center;
    justify-content:center;
    border-top:1px solid white;
    
    /* margin-top:60px; */
}

section.landing-page .about-us div{
    display:flex;
    flex-direction:column;
    justify-content:center;
    
}

section.landing-page .about-us div:nth-of-type(1){
    height:100%;
    padding:0px 50px;
}

section.landing-page .about-us div:nth-of-type(1) h1{
    margin-bottom: 20px;
    color:#d3bfb9;
}



section.landing-page .about-us div:nth-of-type(1) p{
    margin-bottom: 20px;
    font-size: 18px;
    color:white;
    line-height: 1.5em;
font-family: 'Montserrat', sans-serif;
}

section.landing-page .about-us div:nth-of-type(1) a{
    /* margin-top:50px; */
    padding:10px 25px;
    color:white;
    display:block;
    border-radius:20px;
    background:linear-gradient(0deg, #8140b5, #d362a3);
    text-decoration:none;
    transition:all 0.5s ease-in-out;
    width:fit-content;
}

section.landing-page .about-us div:nth-of-type(1) a:hover{
    transform: scale(0.92);
}

section.landing-page .about-us div:nth-of-type(2){
 align-items:center;
}


section.landing-page .about-us div:nth-of-type(2) img{
    height: 599px;
    width:400px;
    /* border:1px solid blue; */
    border-radius:10px;

}

section.landing-page .about-us div .color-2{
    text-shadow: 1px -1px 0px #8140b5, 1px 1px 0px white;
    
    /* animation:color-animation 1s infinite; */
}

@keyframes color-animation{
    0%{
    text-shadow: 1px 0px 0px #d865a3, 1px 1px 0px #8400ff;
       
    }


    50%{
    text-shadow: 1px -1px 0px #8140b5, 1px 1px 0px white;

    }

    100%{
    text-shadow: 1px -1px 0px #d865a3, 1px 1px 0px #8400ff;
    ;
    
    }
  }




  section.landing-page .why-us{
    background:linear-gradient(0deg,#d362a3, #8140b5 );
    padding:20px 30px;
    position:relative;
    top:60px;
    height:100%;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    align-items:center;
    justify-content:center;
    border-top:1px solid white;
  }

  section.landing-page .why-us div{
    display:flex;
    flex-direction:column;
    /* justify-content:center;
    align-items:center; */
}

section.landing-page .why-us div.left{
    align-items:center;
    width:100%;
   }
   
   
   section.landing-page .why-us div.left img{
       height: 600px;
       width:400px;
       /* border:1px solid blue; */
       border-radius:10px;
   
   }

   section.landing-page .why-us div.right h1{
       margin-bottom:20px;
   }

   section.landing-page .why-us div.right div div{
       display:flex;
       flex-direction:row;
       margin:10px 0;
       font-size:20px;
   }

   section.landing-page .why-us div.right div div .fa{
    margin-right:10px;
    color:#8140b5;
   }

   section.landing-page .services{
    background:linear-gradient(0deg,#8140b5,#d362a3 );
    padding:20px 70px;
    position:relative;
    top:60px;
    height:100%;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    align-items:center;
    justify-content:center;
    border-top:1px solid white;
  }

  section.landing-page .services div{
    display:flex;
    flex-direction:column;
    /* justify-content:center;
    align-items:center; */
}

section.landing-page .services div.right{
    align-items:center;
    width:100%;
   }


   section.landing-page .services div.right img{
    height: 500px;
    /* width:400px; */
    /* border:1px solid blue; */
    border-radius:10px;

}

section.landing-page .services div.left h1{
    margin-bottom:20px;
    font-size: 30px;
}

section.landing-page .services div.left div div{
    display:flex;
    flex-direction:row;
    margin:10px 0;
    font-size:18px;
}

section.landing-page .services div.left div div .fa{
    margin-right:10px;
    color:#8140b5;
   }



section.landing-page .pricing{
    background:linear-gradient(0deg,#d362a3,#8140b5 );
    padding:20px 30px;
    position:relative;
    top:60px;
    height:80%;
    display:flex;
    flex-direction:column;
    align-items:center;
    border-top:1px solid white;
    
    
    
}


section.landing-page .pricing h1{
    padding:20px 0;
}


section.landing-page .pricing > div{
    background:#ffffff;
    color:black;
    width:70%;
    height:70%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    border-radius:15px;
    /* overflow-y:scroll; */
    /* border-top-left-radius:50px; */
    
    /* height:100%; */
}

section.landing-page .pricing > div a{
    padding:10px 25px;
    color:white;
    display:block;
    border-radius:20px;
    background:linear-gradient(0deg, #8140b5, #d362a3);
    text-decoration:none;
    transition:all 0.5s ease-in-out;
    width:fit-content;
    margin-top:20px;
}

section.landing-page .pricing > div a:hover{
    transform: scale(0.92);
}

section.landing-page .pricing div  div.table-head,
section.landing-page .pricing div  div.table-data
{
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    width:100%;
    justify-content:center;
    align-items: center;
    /* height:100%; */
}

section.landing-page .pricing div  div.table-head{
    border-bottom:1px solid #cccccc;
    color:#ce85c0;
    font-weight:bolder;
    padding-bottom:20px;
}
section.landing-page .pricing div  div.table-data div{
    margin-top:10px;
}
section.landing-page .pricing div  div.table-data div ul{
margin: 10px 0;
/* color:pink */
}

section.landing-page .pricing div  div.table-head div,
section.landing-page .pricing div  div.table-data div{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
}

section.landing-page .pricing  ul li{
    list-style:none;
}

section.landing-page .partners{
    background:linear-gradient(0deg,#8140b5,#d362a3 );
    /* padding:20px 30px; */
    position:relative;
    /* top:30px; */
    height:20%;
    border-top:1px solid white;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  section.landing-page .partners img{
    /* cursor: pointer; */
    /* filter: grayscale(5); */
    opacity: 0.6;
    display: inline-block;
    vertical-align: middle;
    height: 35px;
    width: auto;
    margin: 0.7em 1.8em;
    transition: all ease-in-out 0.5s;
  }



