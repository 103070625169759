div.cable-details{
    border:2px solid white;
    height:60%;
    width:40%;
    padding: 20px;
    display:flex;
    flex-direction:column;
    justify-content:normal;
    align-items:center;
    border-radius:5px;
    overflow-y:scroll;
    /* background:#9045b1; */
}


div.cable-details > div{
    display:flex;
    flex-direction:column;
    /* border:3px solid white; */
    height:100%;
    width:100%;
    margin:auto;
    padding:2%;
    align-items:center;
    border-radius:10px;
}


div.cable-details form{
    display: flex;
    flex-direction: column;
    margin:auto;
    
    /* justify-content: center; */
    /* align-items: center; */
    width: 40%;
}

div.cable-details form div{
    display:flex;
    flex-direction: column;
    margin:15px 0;
}

div.cable-details form div label{
    margin-bottom:10px;
}


div.cable-details form div input{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #9045b1;
}

div.cable-details div.options{
    display:flex;
    flex-direction:row;
}

div.cable-details p{
    
    /* text-align: center; */
    padding:10px 10px;
    background:#9548b0;
    /* width:20%; */
    cursor:pointer;
    margin:0 5px;
    border-radius:5px;
}

div.cable-details p:hover{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}