@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap);

/* SIDEBAR */

section.sidebar{
    position:fixed;
    background:#1e0338;
    top:70px;
    bottom:0;
    left:0;
    border-right:1px solid white;
    display: flex;
    flex-direction: column;
    /* align-items:center; */
    /* text-align:center; */
    padding:15px 10px;
    font-size: 13px;;
    color:#8e819b;
    width:5%;
    /* height:100%; */
    /* transition: all 1s ease-in-out; */
    /* overflow-y: scroll; */
    
}


section.maximize-sidebar{
    transition: all 0.5s ease-in-out;
    width: 11%;
    border-right:1px solid #7339b8;
}

section.sidebar .direction{
   
    justify-content:flex-end;
    margin:0;
    
    
    border-radius:0;
}



section.sidebar .direction .fa{
font-size:30px;
/* position: absolute; */
top:10px;
right:5px;
}

section.sidebar .direction .fa-chevron-circle-left{
    display: none;
}


section.sidebar .direction  .show-chev{
    display:block;
}

section.sidebar .direction:hover .fa{
    color: #9748b0;
    cursor:pointer;
    
}
section.sidebar p{
    
    margin-left: 7px;;
}


section.sidebar div p.description{
    /* transition: all 1s ease-in-out; */

    /* transition: all 0.5s ease-in; */
    display: none;
}

section.sidebar div p.slowly-show-description{
    display: flex;
        opacity: 1; 
        /* margin-left: 500px; */
    animation: slowly-show 0.5s ease-in;
}

@keyframes slowly-show{
    0%{
        display: none;
        opacity: 0;
        
        
    }

    50%{
        display: none;
        opacity: 0.5;
       

    }
    100%{
        display: flex;
        opacity: 1; 
        
    }
}

section.sidebar .mobile-view-only{
    display:none;
}
section.sidebar .desktop-options{
    position: absolute;
    top:55%;
    /* left:0%; */
    background: #541392;
    left:-100%;
    opacity: 0;
    display:none;
    /* height:60%; */
    /* z-index:1; */
}
section.sidebar .show-options{
    display: block;
    opacity: 1;
    left:0%;
    animation: options 0.5s ease-in
}

@keyframes options{
    from{
        display: none;
        left:-100%;
        opacity: 0;

    }
    to{
        display: block;
        opacity: 1;
        left:0%;
    }
}
section.sidebar div.view-more:hover{
    background: #541392;
    cursor:pointer;
}

section.sidebar div.overview:hover,
section.sidebar div.wallet:hover,
section.sidebar div.transactions-div:hover,
section.sidebar div.data:hover,
section.sidebar div.airtime:hover,
section.sidebar div.cable-tv:hover,
section.sidebar div.electricity:hover,
section.sidebar div.settings:hover,
    section.sidebar div.education:hover,
        section.sidebar div.coupon-code:hover{
    background: #9748b0;
    cursor:pointer;
    color:white;
    
   
}

section.sidebar div.logout:hover{
    background: red;
    cursor:pointer;
    color:white;
}


.side-bar-active{
    background: #9748b0;
    cursor:pointer;
    color:white;
    
}


section.sidebar div{
    
    margin:10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px 7px;
    border-radius: 10px;;
    
}

section.sidebar div{
    align-self: center;
    width: 100%;
}

section.sidebar .services{
    margin-top: 10px;
    padding: 0;
    flex-direction: column;
}

section.sidebar .services h3{
    margin-bottom:10px;
    font-size:18px;
}

section.sidebar .sidebar-footer{
    padding:0;
    flex-direction: column;
    margin-top:7px;
}


@media  (max-width:1050px){
    section.maximize-sidebar{
            
      /* background-color: pink; */
    width: 15%!important;
    
       
    }
    section.sidebar{
        font-size: 13px;
        
        
    }
   
    section.sidebar .direction .fa{
        font-size:25px;
    
        
        }
        section.sidebar p{
    
            margin-left: 5px;;
        }
        
        
}
@media (max-width:920px){
    section.sidebar{
        width:7%;
       
        
    }
    section.sidebar .direction .fa{
        font-size:20px;
    
        
        }
}

@media (max-width:767px){
    section.sidebar{
       
        top:60px;
       
        /* transition: all 1s ease-in-out; */
        
    }
    section.maximize-sidebar{
            
        /* background-color: pink; */
      width: 18%!important;
      
         
      }
      section.sidebar{
        font-size: 11px;
       
        
    }
    
}
@media (max-width:520px){
    section.sidebar .desktop-view-only,
    section.sidebar .hide-on-mobile{
        display: none;
        
    }
    section.sidebar .mobile-view-only{
        display:flex;
    }
    section.sidebar{
       
        top:50px;
        width:10%;
        z-index:1;
        left:-10%;
        position:fixed;
        overflow-y: scroll;
        
    }
   

    section.show-sidebar{
        
        display:flex;
        width:23%!important;
        z-index:5;
        left:0;
        animation:animate-sidebar .5s ease-in-out forwards;
    }

    @keyframes animate-sidebar {
        from{
            /* width:10%; */
            left:-10%;
            /* display:none; */
            opacity:0;
        }

        to{
            display:flex;
            opacity:1;
            left:0;
        }
    }
}

@media (max-width:480px){
    section.show-sidebar{
        
        
        width:25%!important;
        
    }
}
@media (max-width:360px){
    section.show-sidebar{
        
        
        width:33%!important;
        
    }
}
@media (max-width:270px){
    section.show-sidebar{
        
        
        width:50%!important;
        
    }
    section.sidebar{
       
        top:40px;
        
        /* transition: all 1s ease-in-out; */
        
    }
}



/* LANDSCAPE */

@media (max-height:500px){
    section.sidebar{
        overflow-y: scroll;
    }
}
section.overview .grid-wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    padding:20px 0;
    /* justify-content:center; */
    width:100%;
    grid-gap: 20px;
}

@media (max-width:650px){
    section.overview .grid-wrapper{
        
        grid-template-columns: repeat(2,1fr);
        
        
    }
}

@media (max-width:480px){
    section.overview .grid-wrapper{
        
        display: block;
        
        
    }
    section.overview .grid-wrapper div{
        margin:10px 0;
    }
}
div.total-number-of-transactions{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:0 10px;
}

div.total-number-of-transactions div.value{
    /* flex:2; */
    /* border:10px solid #f9a9c6; */
    /* border-radius: 50%; */
    /* width:30px; */
    /* padding:25px 25px; */
    margin-right:10px;
    color:#d2558f;
}

div.total-number-of-transactions >p{
    font-weight:bold;
}
@media (max-width:850px){
    div.total-number-of-transactions div.value{
       
        /* border:5px solid #f9a9c6; */
        /* padding:15px 15px; */
        margin-right:10px;
       
    }
    div.total-number-of-transactions >p{
        
        font-size:15px;
    }
}

@media (max-width:650px){
    div.total-number-of-transactions{
        padding:50px 5px;
    }
    div.total-number-of-transactions div.value{
       font-size: 20px;
       
    }
}

@media (max-width:480px){
    div.total-number-of-transactions >p{
        
        font-size:18px;
    }
}

@media (max-width:270px){
    div.total-number-of-transactions div.value{
        font-size: 15px;
        
     }
    div.total-number-of-transactions >p{
        
        font-size:13px;
    }
}
div.total-transactions{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:0 5px;
}

div.total-transactions div.value{
    
    border-radius: 100%;
   
    margin-right:10px;
    color:#d2558f;
    font-size:30px;
}
div.total-transactions div.value h5{
    color:white;
    font-weight: bolder;
}

div.total-transactions >p{
    font-weight:bold;
    font-size:20px;
}
@media (max-width:850px){
    
    div.total-transactions div.value{
       
        
        font-size:25px;
        margin-right:10px;
       
    }
    div.total-transactions >p{
        font-weight:bold;
        font-size:15px;
    }
   
}

@media (max-width:650px){
    div.total-transactions{
        padding:50px 5px;
    }
    div.total-transactions div.value{
       
        
        font-size:30px;
       
       
    }
}

@media (max-width:480px){
    div.total-transactions >p{
      
        font-size:16px;
    }
}
@media (max-width:270px){
    div.total-transactions >p{
      
        font-size:13px;
    }
    div.total-transactions div.value{
       
        
        font-size:25px;
       
       
    }
}
div.wallet-balance{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:50px 5px;
}


div.wallet-balance div.value{
     /* flex:2; */
    /* border:10px solid #f9a9c6; */
    border-radius: 100%;
    /* width:40%; */
    /* padding:25px 25px; */
    margin-right:10px;
    color:#d2558f;
    font-size:30px;
}

div.wallet-balance div.value h5{
    color:white;
    font-weight: bolder;
}

div.wallet-balance >p{
    font-weight:bold;
    font-size:20px;
}
@media (max-width:850px){
    div.wallet-balance div.value{
      
      
       
       font-size:25px;
   }
   div.wallet-balance >p{
    
    font-size:15px;
}
}
@media (max-width:650px){
    div.wallet-balance{
 grid-column: 1/4;
    }
    div.wallet-balance >p{
       
        font-size:20px;
    }
    div.wallet-balance div.value{
      
      
       
        font-size:30px;
    }
}

@media (max-width:270px){
   
    div.wallet-balance >p{
       
        font-size:15px;
    }
    div.wallet-balance div.value{
      
      
       
        font-size:20px;
    }
}
div.last5transactions{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
   display:flex;
   flex-direction:column;
   padding:2% 5%;
    margin:10px auto;
    
    /* grid-column:1/4; */
}

div.last5transactions h3{
    font-size:20px;
    font-weight:bold;
    margin-bottom:15px;
}

div.last5transactions table{
    /* text-align:center; */
}

div.last5transactions .table-label ul{
    display:flex;
    justify-content: space-between;
    margin-bottom:10px;
    padding:0 30px;
    /* align-items: center; */

}
div.last5transactions .table-label ul li{
    list-style:none
}

div.last5transactions .table-data ul{
    display:flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    padding:15px 20px;
    border-radius:15px;
    margin: 10px 0;
}
div.last5transactions .table-data ul li{
    list-style:none
}

.status{
    text-align:center;
    padding:5px 5px;
    border-radius:10px;
    width: 100px;
}
@media (max-width:767px){
    

    div.last5transactions h3{
        font-size:15px;
        
    }
    div.last5transactions .table-label ul li{
        font-size:13px;
    }
    div.last5transactions .table-data ul li{
        font-size:13px;
}
    
}

@media (max-width:520px){
    div.last5transactions .table-label ul{
        padding:0 15px;
}
    div.last5transactions{
        padding:2% 2%;
    }
    div.last5transactions .table-data ul{
        
        padding:15px 5px;
        
    }
    div.last5transactions .table-data ul li{
        font-size:10px;
}
.status{
   
    padding:5px 1px!important;
    width: 70px!important;
}
}

@media (max-width:360px){
    div.last5transactions .hide-on-mobile{
        display:none;
    }
}

@media (max-width:270px){
   
    div.last5transactions .table-data ul li{
        font-size:8px;
        padding:0px 3px;
}
.status{
   
    padding:5px 1px!important;
    width: 80px!important;
}

}
section.wallet .wrapper{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding:20px 0;
    /* justify-content:center; */
    width:100%;
    grid-gap: 20px;
    margin-top: 10%;
}


section.wallet h2{
    font-size: 30px;
}
@media (max-width:650px){
    section.wallet h2{
        font-size: 25px;
    }
}
@media (max-width:480px){
    
    section.wallet .wrapper{
        display: block;
        
        
    }
    section.wallet .wrapper div{
        margin:10px 0;
    }
}
div.personal-wallet{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:60px 0;
}

div.personal-wallet .left h3{
    font-size:30px;
    color:#ffa92c;
    border:2px dotted #fc5eac;
    padding:10px;

}

div.personal-wallet .left h5{
    padding:15px 0;
    font-size:25px;
    color:#fc5eac;
}

div.personal-wallet .left h5 p{
    color:white;
}

div.personal-wallet .right p{
    background: #300d42;
    padding: 10px 10px;
    border-radius:10px;
    font-size:15px;
}

div.personal-wallet .right p:hover{
    cursor:pointer;
    transform:scale(0.95) ;

}
@media (max-width:850px){
    div.personal-wallet .left h3{
        font-size:20px;
        }
        div.personal-wallet .left h5{
         
            font-size:20px;
            
        }
        div.personal-wallet .right p{
           
            
            font-size:13px;
        }
}
@media (max-width:650px){
    div.personal-wallet{
        
        display:flex;
        flex-direction:column;
        text-align:center;
        align-items:center;
        padding:60px 0;
    }
    div.personal-wallet .left h3{
        font-size:20px;
        }
        div.personal-wallet .left h5{
         
            font-size:20px;
            
        }
        div.personal-wallet .right p{
           
            
            font-size:13px;
        }
}
div.shared-wallet{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:60px 0;
}



div.shared-wallet .left h3{
    font-size:30px;
    color:#ffa92c;
    border:2px dotted #fc5eac;
    padding:10px;

}

div.shared-wallet .left h5{
    font-size:15px;
    margin-top:10px;

}

div.shared-wallet .left form{
    display: flex;;
    flex-direction: column;
    margin-top:10px;
}

div.shared-wallet .left form div{
    display: flex;;
    flex-direction: column;
    margin:10px 0;
    align-items: baseline;
}
div.shared-wallet .left form div label{
    margin-bottom:5px;
}
div.shared-wallet .left form div input{
    background: #300d42;
    padding:10px 15px;
    color:white;
    border:none;
    outline:none;
}



div.shared-wallet .right p{
    background: #300d42;
    padding: 10px 10px;
    border-radius:10px;
    font-size:15px;
}

div.shared-wallet .right p:hover{
    cursor:pointer;
    transform:scale(0.95) ;

}
@media (max-width:850px){
    div.shared-wallet .left h3{
        font-size:18px;
        padding:10px 5px;
    }
    div.shared-wallet .left form div label{
        font-size:14px;
    }
    div.shared-wallet .left form div input{
       
        padding:10px 10px;
        
    }
    div.shared-wallet .right p{
        
        font-size:13px;
    }
}

@media (max-width:767px){
    div.shared-wallet{
       
        flex-direction: column;
        
    }
    div.shared-wallet .right p{
        margin-top:10px;
        font-size:15px;
    }
    
}
div.transactions .table{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
   display:flex;
   flex-direction:column;
   padding:2% 5%;
    margin:20px auto;
    font-family: 'Roboto Mono', monospace;
    /* grid-column:1/4; */
}

div.transactions h3{
    font-size:30px;
    font-weight:bold;
    margin-bottom:25px;
    padding-top:5px;
}

div.transactions .header{
    display:flex;
    justify-content: space-between;
    align-items:baseline;
}
div.transactions .header div select{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    /* width:100%; */
    background:transparent;
    border:1px solid #7e3eb5;
}
div.transactions .header div select option{
    color: white;
    background:#1e0338;
    border:none;
    outline:none;
    padding:3px 0;
}
div.transactions .table-label ul{
    display:flex;
    justify-content: space-between;
    margin-bottom:10px;
    padding:0 30px;
    /* align-items: center; */

}
div.transactions .table-label ul li{
    list-style:none
}

div.transactions .table-data ul{
    display:flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    padding:15px 20px;
    border-radius:15px;
    margin: 10px 0;
}

div.transactions .table-data ul:hover{
    cursor:pointer;
    transition:all 0.3s ease-in-out;
    border-color:#b454aa;
    background:#b454aa;
    transform:scale(1.05)
}
div.transactions .table-data ul li{
    list-style:none
}

.status{
    text-align:center;
    padding:5px 5px;
    border-radius:10px;
    width: 100px;
}
@media (max-width:650px){
    div.transactions h3{
        font-size:20px;
        
    }
    div.transactions .header div select{
        
        padding:5px 5px;
       
    }
    div.transactions .table-label ul{
        font-size:13px;
        padding:0 15px;
        /* align-items: center; */
    
    }
    div.transactions .table-data ul li{
        font-size:13px;
}
}

@media (max-width:520px){
   
    div.transactions .table-data ul li{
        font-size:10px;
}
.status{
   
    padding:5px 1px!important;
    width: 70px!important;
}
div.transactions .table-data ul{
    
    padding:15px 5px;
    
}
}

@media (max-width:360px){
    div.transactions  .hide-on-mobile{
        display:none;
    }
}

@media (max-width:270px){
    div.transactions h3{
        font-size:15px;
        
    }
   
    div.transactions .table-data ul li{
        font-size:8px;
        padding:0px 3px;
}
.status{
   
    padding:5px 1px!important;
    width: 80px!important;
}
div.transactions .header div select{
        font-size:10px;
    padding:2px 5px;
   
}

}
section.airtime > div{
    
    border:3px solid white;
    height:100%;
    width:70%;
    margin:auto;
    padding:5%;
    align-items:center;
    border-radius:10px;
}
section.airtime > div > div >div{
    display: flex;
    flex-direction:column;
    align-items:center;
}

section.airtime div.header{
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 60%;
    /* background: #43275f; */
    /* padding: 20px 0 */
}
section.airtime div.header h4{
    font-size:20px;
    padding: 10px 10px;
    cursor: pointer
}
section.airtime h5{
    text-align: center;
}
section.airtime h3{
    display:flex;
    font-size:30px;
    /* margin-bottom:20px; */
    width:70%;
    margin:20px auto;
}

section.airtime  div.header h4.left-active{
    background: #2c1d3a;
    /* padding: 10px 20px; */
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

section.airtime  div.header h4.right-active{
    background: #2c1d3a;
   
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
section.airtime  div.modal-right{
    display: none;
}

section.airtime form{
    display: flex;
    flex-direction: column;
    margin:auto;
    margin-bottom:10px;
    /* justify-content: center; */
    /* align-items: center; */
    width: 60%;
    background: #2c1d3a;
    /* margin: 0; */
    padding: 10px 20px;
}

section.airtime form div{
    display:flex;
    flex-direction: column;
    margin:15px 0;
}

section.airtime form div:nth-of-type(6){
    flex-direction: row;
    align-items:center;
}

section.airtime form div:nth-of-type(6) label{
    margin:0 10px;
}

section.airtime form div:nth-of-type(6) input{
    background:#1e0338;
}
section.airtime form div label{
    margin-bottom:10px;
}

section.airtime form div select option{
    color: white;
    background:#1e0338;
    border:none;
    outline:none;
    padding:3px 0;
}

section.airtime form div select option:hover{
    background: brown;
}


section.airtime form div input,
section.airtime form div select{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #7e3eb5;
}

section.airtime form div select{
    color: -internal-light-dark(black, white) ;

}

section.airtime form div.check p{
    text-align: center;
    padding:5px 5px;
    background:#9548b0;
    width:10%;
    height:30px;
    /* align-items:ce nter; */
}

section.airtime form div.check p .fa{
    display:none;
}

section.airtime form div.check p:hover{
    transform:none
}


section.airtime p{
    text-align: center;
    padding:10px 10px;
    background:#2c1d3a;
    width:20%;
    cursor:pointer;
    margin-bottom:5px;
}

section.airtime p:hover{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}
@media (max-width:920px){
    section.airtime div.header h4{
        font-size:15px;
        padding: 10px 10px;
        
    }
}


@media (max-width:850px){
    section.airtime form div.check label{
       font-size: 13px;;
    }
    section.airtime > div{
        
        width:80%;
        
        padding:2%;
        
    }
    section.airtime p{
        
        width:30%;
        
    }

   
}

@media (max-width:650px){
    section.airtime h3{
      
        font-size:25px;
    
    }
    section.airtime h5{
        font-size:12px;
    }
    section.airtime > div{
        
        width:80%;
        
        padding:1%;
        
    }
    section.airtime form{
      
        width: 70%;
    }
    section.airtime div.header{
      
        width: 70%;
        
    }
}

@media (max-width:520px){
    section.airtime form{
      
        width: 80%;
    }
    section.airtime div.header{
      
        width: 80%;
        
    }
}

@media (max-width:480px){
    section.airtime > div{
        
        width:90%;
        
        
        
    }
    
    section.airtime form div label{
        font-size:13px;
    }
    section.airtime form div{
        
        margin:10px 0;
    }   
    section.airtime p{
        font-size: 14px;
    }
    section.airtime form div.check p .fa{
        display:none;
        font-size:14px;
    }
    section.airtime form div.check p{
        
        padding:5px 0px;
        width: 25px;
        height: 25px;
        
    }
}

@media (max-width:360px){
    section.airtime form{
      
        width: 90%;
    }
    section.airtime div.header{
      
        width: 90%;
        
    }
    section.airtime p{
        
        width:40%;
        
    }
}

@media (max-width:270px){
    section.airtime > div{
        
        width:100%;
        
        
        
    }
    section.airtime p{
        font-size:13px;
        padding:10px 5px;
        
    }

    section.airtime div.header h4{
       
        padding: 10px 5px;
        
    }
}
section.data h3{
    display:flex;
    font-size:30px;
    /* margin-bottom:20px; */
    width:70%;
    margin:20px auto;
}

section.data h5{
    text-align:center;
}

section.data > div {
   
    border:3px solid white;
    height:100%;
    width:70%;
    margin:auto;
    padding:2%;
    align-items:center;
    border-radius:10px;
}

section.data > div > div >div{
    display: flex;
    flex-direction:column;
    align-items:center;
}
section.data div.header{
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 60%;
    /* background: #43275f; */
    /* padding: 20px 0 */
}

section.data div.header h4{
    font-size:20px;
    padding: 10px 10px;
    cursor: pointer
}
section.data div.header h4.left-active{
    background: #2c1d3a;
    /* padding: 10px 20px; */
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

section.data div.header h4.right-active{
    background: #2c1d3a;
   
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
section.data div.modal-right{
    display: none;
}

section.data form{
    display: flex;
    flex-direction: column;
    margin:auto;
    margin-bottom:10px;
    /* justify-content: center; */
    /* align-items: center; */
    width: 60%;
    background: #2c1d3a;
    /* margin: 0; */
    padding: 10px 20px;
}

section.data form div{
    display:flex;
    flex-direction: column;
    margin:15px 0;
}

section.data form div label{
    margin-bottom:10px;
}

section.data form div select option{
    color: white;
    background: #2c1d3a;
    border:none;
    outline:none;
    padding:3px 0;
}

section.data form div select option p:active{
    background: brown!important;

}

section.data form div select option:hover{
    background: brown;
}


section.data form div input,
section.data form div select{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #7e3eb5;
}


section.data form div.check p{
    text-align: center;
    padding:5px 5px;
    background:#9548b0;
    width:10%;
    height:30px;
    align-items:center;
}

section.data form div.check p .fa{
    display:none;
}

section.data form div.check p:hover{
    transform:none
}

section.data form div:nth-of-type(6){
    flex-direction: row;
    align-items:center;
}

section.data form div:nth-of-type(6) label{
    margin:0 10px;
}

section.data form div:nth-of-type(6) input{
    background:#1e0338;
}

section.data p{
    text-align: center;
    padding:10px 10px;
    background:#2c1d3a;
    width:20%;
    cursor:pointer;
    margin-bottom:5px;
}

section.data p:hover{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}
@media (max-width:920px){
    section.data div.header h4{
        font-size:15px;
        
    }
}
@media (max-width:850px){
    section.data  form div.check label{
       font-size: 13px;;
    }
    section.data  > div{
        
        width:80%;
        
        padding:2%;
        
    }
    section.data  p{
        
        width:30%;
        
    }
}
@media (max-width:650px){
    section.data h3{
        margin:10px auto;
        font-size:25px;
    
    }
    section.data h5{
        font-size:12px;
    }
    section.data > div{
        
        width:80%;
        
        padding:1%;
        
    }
    section.data form{
      
        width: 70%;
    }
    section.data div.header{
        
        width: 70%;
       
    }
}

@media (max-width:520px){
    section.data form{
      
        width: 80%;
    }
    section.data div.header{
        
        width: 80%;
       
    }
}

@media (max-width:480px){
    section.data > div{
        
        width:90%;
        
        
        
    }
   
    
    section.data form div label{
        font-size:13px;
    }
    section.data form div{
        
        margin:10px 0;
    }   
    section.data p{
        font-size: 14px;
    }
    section.data form div.check p .fa{
        display:none;
        font-size:14px;
    }
    section.data form div.check p{
        
        padding:5px 0px;
        width: 25px;
        height: 25px;
        
    }
}

@media (max-width:360px){
    section.data form{
      
        width: 90%;
    }
    section.data p{
        
        width:40%;
        
    }
    section.data div.header{
        
        width: 90%;
       
    }
}

@media (max-width:270px){
    section.data > div{
        
        width:100%;
        
        
        
    }
    section.data p{
        font-size:13px;
        padding:10px 5px;
        
    }
    section.data div.header h4{
       
        padding: 10px 5px;
       
    }
}
section.cabletv h3{
    display:flex;
    font-size:30px;
    /* margin-bottom:20px; */
    width:70%;
    margin:20px auto;
}


section.cabletv h5{
    text-align:center;
}
section.cabletv > div{
    
    border:3px solid white;
    height:100%;
    width:70%;
    margin:auto;
    padding:2%;
    align-items:center;
    border-radius:10px;
}
section.cabletv > div > div >div{
    display: flex;
    flex-direction:column;
    align-items:center;
}

section.cabletv div.header{
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 60%;
    /* background: #43275f; */
    /* padding: 20px 0 */
}
section.cabletv div.header h4{
    font-size:20px;
    padding: 10px 10px;
    cursor: pointer
}
section.cabletv div.header h4.left-active{
    background: #2c1d3a;
    /* padding: 10px 20px; */
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

section.cabletv div.header h4.right-active{
    background: #2c1d3a;
   
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
section.cabletv div.modal-right{
    display: none;
}
section.cabletv form{
    display: flex;
    flex-direction: column;
    margin:auto;
    margin-bottom:10px;
    /* justify-content: center; */
    /* align-items: center; */
    width: 60%;
    background: #2c1d3a;
    /* margin: 0; */
    padding: 10px 20px;
}


section.cabletv form div{
    display:flex;
    flex-direction: column;
    margin:15px 0;
}

section.cabletv form div label{
    margin-bottom:10px;
}

section.cabletv form div select option{
    color: white;
    background:#1e0338;
    border:none;
    outline:none;
    padding:3px 0;
}

section.cabletv form div input,
section.cabletv form div select{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #7e3eb5;
}

section.cabletv p{
    text-align: center;
    padding:10px 10px;
    background:#2c1d3a;
    width:20%;
    cursor:pointer;
    margin-bottom:5px;
}

section.cabletv p:hover{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}
@media (max-width:920px){
    section.cabletv div.header h4{
        font-size:15px;
        
        
    }
}

@media (max-width:850px){
    section.cabletv  form div.check label{
       font-size: 13px;;
    }
    section.cabletv  > div{
        
        width:80%;
        
        padding:2%;
        
    }
    section.cabletv p{
        
        width:30%;
        
    }
}

@media (max-width:650px){
    section.cabletv h3{
        margin:10px auto;
        font-size:25px;
    
    }
    section.cabletv h5{
        font-size:12px;
    }
    section.cabletv > div{
        
        width:80%;
        
        padding:1%;
        
    }
    section.cabletv form{
      
        width: 70%;
    }
    section.cabletv div.header{
        
        width: 70%;
        
    }
}

@media (max-width:520px){
    section.cabletv form{
      
        width: 80%;
    }
    section.cabletv div.header{
        
        width: 80%;
        
    }
}

@media (max-width:480px){
    section.cabletv > div{
        
        width:90%;
        
        
        
    }
    
    section.cabletv form div label{
        font-size:13px;
    }
    section.cabletv form div{
        
        margin:10px 0;
    }   
    section.cabletv p{
        font-size: 14px;
    }
    
}

@media (max-width:360px){
    section.cabletv form{
      
        width: 90%;
    }
    section.cabletv p{
        
        width:40%;
        
    }
    section.cabletv div.header{
        
        width: 90%;
        
    }
}

@media (max-width:270px){
    section.cabletv> div{
        
        width:100%;
        
        
        
    }
    section.cabletv p{
        font-size:13px;
        padding:10px 5px;
        
    }
    section.cabletv div.header h4{
        /* font-size:15px; */
        padding:10px 5px;
        
    }
}
section.electricity h3{
    display:flex;
    font-size:30px;
    /* margin-bottom:20px; */
    width:70%;
    margin:20px auto;
}

section.electricity h5{
    text-align:center;
}

section.electricity > div{
   
    border:3px solid white;
    height:100%;
    width:70%;
    margin:auto;
    padding:2%;
    align-items:center;
    border-radius:10px;
}
section.electricity > div > div >div{
    display: flex;
    flex-direction:column;
    align-items:center;
}
section.electricity div.header{
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 60%;
    /* background: #43275f; */
    /* padding: 20px 0 */
}
section.electricity div.header h4{
    font-size:20px;
    padding: 10px 10px;
    cursor: pointer
}
section.electricity div.header h4.left-active{
    background: #2c1d3a;
    /* padding: 10px 20px; */
    border-top-right-radius: 5px;
    border-top-left-radius: 5px
}

section.electricity div.header h4.right-active{
    background: #2c1d3a;
   
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
section.electricity div.modal-right{
    display: none;
}
section.electricity form{
    display: flex;
    flex-direction: column;
    margin:auto;
    margin-bottom:10px;
    /* justify-content: center; */
    /* align-items: center; */
    width: 60%;
    background: #2c1d3a;
    /* margin: 0; */
    padding: 10px 20px;
}

section.electricity form div{
    display:flex;
    flex-direction: column;
    margin:15px 0;
}

section.electricity form div label{
    margin-bottom:10px;
}

section.electricity form div select option{
    color: white;
    background:#1e0338;
    border:none;
    outline:none;
    padding:3px 0;
}



section.electricity form div input,
section.electricity form div select{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #7e3eb5;
}


section.electricity p{
    text-align: center;
    padding:10px 10px;
    background:#2c1d3a;
    width:20%;
    cursor:pointer;
    margin-bottom:5px;

}

section.electricity p:hover{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}
@media (max-width:920px){
    section.electricity div.header h4{
        font-size:15px;
    }
}

@media (max-width:850px){
    section.electricity  form div.check label{
       font-size: 13px;;
    }
    section.electricity  > div{
        
        width:80%;
        
        padding:2%;
        
    }
    section.electricity p{
        
        width:30%;
        
    }
}

@media (max-width:650px){
    section.electricity h3{
        margin:10px auto;
        font-size:25px;
    
    }
    section.electricity h5{
        font-size:12px;
    }
    section.electricity > div{
        
        width:80%;
        
        padding:1%;
        
    }
    section.electricity form{
      
        width: 70%;
    }
    section.electricity div.header{
        
        width: 70%;
       
    }
}

@media (max-width:520px){
    section.electricity form{
      
        width: 80%;
    }
    section.electricity div.header{
        
        width: 80%;
       
    }
}

@media (max-width:480px){
    section.electricity > div{
        
        width:90%;
        
        
        
    }
    
    section.electricity form div label{
        font-size:13px;
    }
    section.electricity form div{
        
        margin:10px 0;
    }   
    section.electricity p{
        font-size: 14px;
    }
    
}

@media (max-width:360px){
    section.electricity form{
      
        width: 90%;
    }
    section.electricity p{
        
        width:40%;
        
    }
    section.electricity div.header{
        
        width: 90%;
       
    }
}

@media (max-width:270px){
    section.electricity> div{
        
        width:100%;
        
        
        
    }
    section.electricity p{
        font-size:13px;
        padding:10px 5px;
        
    }
    section.electricity div.header h4{
        padding:10px 5px;
    }
}
section.settings h3{
    display:flex;
    font-size:30px;
    /* margin-bottom:20px; */
    width:70%;
    margin:20px auto;
}

section.settings > div{
    display:flex;
    flex-direction:column;
    border:3px solid white;
    height:100%;
    width:70%;
    margin:auto;
    padding:2%;
    /* align-items:center; */
    border-radius:10px;
}

section.settings div.profile,
section.settings div.password,
section.settings div.pin
{
    border:1px solid #a49bac;
    padding:10px 25px;
    margin-bottom:5px;
    
}

section.settings div.profile .header,
section.settings div.password .header,
section.settings div.pin .header{
    position: relative;
    display:flex;
    /* justify-content: space-between; */
}

section.settings div.profile .header .fa,
section.settings div.password .header .fa,
section.settings div.pin .header .fa{
    position: absolute;
    right:0;
}

section.settings div.profile .header .fa:hover,
section.settings div.password .header .fa:hover,
section.settings div.pin .header .fa:hover{
    color:#9a49af;
    cursor:pointer;
}


section.settings div.profile .header .fa-chevron-up,
section.settings div.password .header .fa-chevron-up,
section.settings div.pin .header .fa-chevron-up{
    display:none;
}

section.settings div.profile .form,
section.settings div.password .form,
section.settings div.pin .form{
    display:none;
    margin-top:20px;
    /* display:flex; */
    flex-direction:column;
    justify-content:center;
    align-items:center;
    
    
}

section.settings div.profile .show-form,
section.settings div.password .show-form,
section.settings div.pin .show-form{
    display:flex;
  
    animation:show-form .6s ease-in-out;
   
   
}

@keyframes show-form{
    from{
        opacity:0;
    }

    to{
        opacity:1;
        height:50%;
    }
}

section.settings div.profile .form form div,
section.settings div.password .form form div,
section.settings div.pin .form form div{
    margin:15px 0;
    display:flex;
    flex-direction:column;
}

section.settings div.profile .form form div label,
section.settings div.password .form form div label,
section.settings div.pin .form form div label{
    margin-bottom:10px;
    font-size:14px;
    color:#93879f;
}
section.settings div.profile .form form div input,
section.settings div.password .form form div input,
section.settings div.pin .form form div input{
    background: transparent;
    color:white;
    border:1px solid #93879f;
    padding:10px 15px;
    border-radius:15px;
    font-size:14px;
    outline:none;
}
section.settings div.profile .form form div{
    position:relative;
}
section.settings div.profile .form form div i{
    color:white;
    position: absolute;
    right: 10px;
    top: 58%;
    cursor:pointer;
}
section.settings div.profile .form form div p.copy-success-message{
    color:white;
    position: absolute;
    right: 10px;
    font-size:11px;
    display:none;
    
    /* top: 58%; */
    cursor:pointer;
}
section.settings div.profile .form form div i:hover{
    color:#93879f;
    
}
section.settings div.pin .form form  p{
    text-align:center;
    display:block;
}
section.settings div.pin .form  h4,
section.settings div.password .form  h4{
    /* color:pink; */
    font-size:15px;
    text-align:center;
    width:50%;
}
section.settings div.password .form form div h6,
section.settings div.pin .form form div h6{
    margin-top:5px;
    color:#93879f;
}

section.settings div.password .form p,
section.settings div.pin .form p{
    background-color: #b856a9;
    padding:10px 15px;
    border-radius: 5px;;
}


section.settings div.password .form p:hover,
section.settings div.pin .form p:hover{
    cursor: pointer;
    transform: scale(0.95);
}




@media (max-width:650px){
    section.settings > div{
     
        width:80%;
        margin:auto;
        padding:2%;
        
    }
    section.settings h3{
        
        font-size:25px;
       
        margin:10px auto;
    }
    section.settings .header h2{
        font-size:15px;
    }

    section.settings div.profile .header .fa,
section.settings div.password .header .fa,
section.settings div.pin .header .fa{
    position: absolute;
    right:0;
    font-size:13px;
}
section.settings div.profile .form form div label,
section.settings div.password .form form div label,
section.settings div.pin .form form div label{
    
    font-size:13px;
    
}
section.settings div.profile .form form div input,
section.settings div.password .form form div input,
section.settings div.pin .form form div input{
    background: transparent;
    color:white;
    border:1px solid #93879f;
    padding:7px 10px;
    border-radius:13px;
    font-size:13px;
    
}
section.settings div.password .form p,
section.settings div.pin .form p{
    background-color: #b856a9;
    padding:10px 10px;
    font-size:13px;
    border-radius: 5px;;
}
}

@media (max-width:360px){
    section.settings > div{
     
        width:100%;
        
        
    }
}
section.coupons .wrapper{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding:20px 0;
    /* justify-content:center; */
    width:100%;
    grid-gap: 20px;
    margin-top: 10%;
}


section.coupons h2{
    font-size: 30px;
}
@media (max-width:650px){
    section.coupons h2{
        font-size: 25px;
    }
}
@media (max-width:480px){
    
    section.coupons .wrapper{
        display: block;
        
        
    }
    section.coupons .wrapper div{
        margin:10px 0;
    }
}
div.buy-coupon{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    flex-direction:column;
    /* justify-content:space-around; */
    /* text-align:center; */
    align-items:center;
    padding:60px 0;
}
div.buy-coupon h1{
    margin-bottom:20px;
    font-size:30px;
}
div.buy-coupon form h5{
    text-align:center;
}
div.buy-coupon form{
    display: flex;
    flex-direction: column;
    /* margin:auto; */
    /* justify-content: center; */
    /* align-items: center; */
    width: 60%;
}
div.buy-coupon form div{
    display:flex;
    flex-direction: column;
    margin:15px 0;
}
div.buy-coupon form div label{
    margin-bottom:10px;
}
div.buy-coupon form div input,
div.buy-coupon form div select{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #7e3eb5;
}
div.buy-coupon form div select option{
    color: white;
    background:#1e0338;
    border:none;
    outline:none;
    padding:3px 0;
}

div.buy-coupon form div.units div{
    /* display: flex; */
    flex-direction:row;
    align-items: baseline;
}
div.buy-coupon form div.units div span{
    margin:0 10px;
}

div.buy-coupon form div.units div .fa{
    background:#7e3eb5;
    padding:7px;
    cursor: pointer;
}
div.buy-coupon form div.units div .fa:active{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}
div.buy-coupon p{
    text-align:center;
    text-align: center;
    padding:10px 10px;
    background:#9548b0;
    width:30%;
    cursor:pointer;
    margin-bottom:5px;
}

div.buy-coupon p:hover{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}
@media (max-width:767px){
    div.buy-coupon form{
        
        width: 70%;
    }
}

@media (max-width:650px){
    div.buy-coupon p{
        
        width:40%;
        
    }
}

@media (max-width:520px){
    div.buy-coupon form{
        
        width: 90%;
    }
    div.buy-coupon p{
        
        width:50%;
        
    }
}
div.available-coupon{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    flex-direction:column;
    /* justify-content:space-around; */
    /* text-align:center; */
    align-items:center;
    padding:60px 0;
}
div.available-coupon h1{
    margin-bottom:20px;
    font-size:30px;
}

div.available-coupon div.coupon{
    background: #8140b5;
    margin-bottom: 20px;
    padding: 15px;
    width: 80%;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
div.available-coupon div.coupon:hover{
    transition: all 0.6s ease-in-out;
    cursor: pointer;
    transform: scale(0.95);
}
div.available-coupon div.pagination-box{
    display:flex;
    align-items: center;
    margin-top:20px;
}
div.available-coupon div.pagination-box i{
    font-size:30px;
}

div.available-coupon div.pagination-box i:hover{
    color:#8140b5;
    cursor:pointer;
}
div.available-coupon div.pagination-box div{
    display:flex;
    margin:0 10px;
}

div.available-coupon div.pagination-box div p{
    margin-right:5px;
}
@media (max-width:1050px){
    div.available-coupon div.coupon{
        font-size: 15px;
        width: 85%;
    }
}

@media (max-width:920px){
    div.available-coupon div.coupon{
        font-size: 13px;
        /* width: 85%; */
    }
}

@media (max-width:850px){
    div.available-coupon div.coupon{
        padding: 10px;
        width: 90%;
    }
}

@media (max-width:360px){
    div.available-coupon div.coupon{
        font-size: 10px;
    }
}
section.education h3{
    display:flex;
    font-size:30px;
    /* margin-bottom:20px; */
    width:70%;
    margin:20px auto;
}
section.education h5{
    text-align:center;
}
section.education > div{
    
    border:3px solid white;
    height:100%;
    width:70%;
    margin:auto;
    padding:2%;
    align-items:center;
    border-radius:10px;
}
section.education > div > div >div{
    display: flex;
    flex-direction:column;
    align-items:center;
}
section.education div.header{
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 60%;
    /* background: #43275f; */
    /* padding: 20px 0 */
}
section.education div.header h4{
    font-size:20px;
    padding: 10px 10px;
    cursor: pointer
}
section.education div.header h4.left-active{
    background: #2c1d3a;
    /* padding: 10px 20px; */
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

section.education div.header h4.right-active{
    background: #2c1d3a;
   
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
section.education div.modal-right{
    display: none;
}
section.education form{
    display: flex;
    flex-direction: column;
    margin:auto;
    margin-bottom:10px;
    /* justify-content: center; */
    /* align-items: center; */
    width: 60%;
    background: #2c1d3a;
    /* margin: 0; */
    padding: 10px 20px;
}


section.education form div{
    display:flex;
    flex-direction: column;
    margin:15px 0;
}
section.education form div label{
    margin-bottom:10px;
}

section.education form div select option{
    color: white;
    background:#1e0338;
    border:none;
    outline:none;
    padding:3px 0;
}
section.education form div input,
section.education form div select{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #7e3eb5;
}
section.education p{
    text-align: center;
    padding:10px 10px;
    background:#2c1d3a;
    width:20%;
    cursor:pointer;
    margin-bottom:5px;
}

section.education p:hover{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}
section.education form .units div{
    /* display: flex; */
    flex-direction:row;
    align-items: baseline;
}
section.education form div.units div span{
    margin:0 10px;
}

section.education form div.units div .fa{
    background:#3c3840;
    padding:7px;
    cursor: pointer;
}
section.buy-coupon form div.units div .fa:active{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}
@media (max-width:920px){
    section.education div.header h4{
        font-size:15px;
        
    }
}

@media (max-width:850px){
    section.education  form div.check label{
       font-size: 13px;;
    }
    section.education  > div{
        
        width:80%;
        
        padding:2%;
        
    }
    section.education  p{
        
        width:30%;
        
    }
}

@media (max-width:650px){
    section.education h3{
        margin:10px auto;
        font-size:25px;
    
    }
    section.education h5{
        font-size:12px;
    }
    section.education > div{
        
        width:80%;
        
        padding:1%;
        
    }
    section.education form{
      
        width: 70%;
    }
    section.education div.header{
        
        width: 70%;
       
    }
}

@media (max-width:520px){
    section.education form{
      
        width: 80%;
    }
    section.education div.header{
        
        width: 80%;
       
    }
}
@media (max-width:480px){
    section.education > div{
        
        width:90%;
        
        
        
    }
   
    
    section.education form div label{
        font-size:13px;
    }
    section.education form div{
        
        margin:10px 0;
    }   
    section.education p{
        font-size: 14px;
    }
    section.education form div.check p .fa{
        display:none;
        font-size:14px;
    }
    section.education form div.check p{
        
        padding:5px 0px;
        width: 25px;
        height: 25px;
        
    }
}
@media (max-width:360px){
    section.education form{
      
        width: 90%;
    }
    section.education p{
        
        width:40%;
        
    }
    section.education div.header{
        
        width: 90%;
       
    }
}

@media (max-width:270px){
    section.education > div{
        
        width:100%;
        
        
        
    }
    section.education p{
        font-size:13px;
        padding:10px 5px;
        
    }
    section.education div.header h4{
       
        padding: 10px 5px;
       
    }
}



header.dashboard-header{
    position:fixed;
    z-index:5;
    background-color:#1e0338;
    right: 0;
    left: 0;
    height:70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:15px 20px;
    border-bottom:1px solid white;
    
}

header.dashboard-header img.site-logo {
    height: 135px;
    background:transparent;
    /* display */
    /* color: aquamarine; */
}


.header-right{
    display: flex;
    align-items: center;
    
}

div.header-right div{
    border-color:white;
}
img.profile-picture{
    height: 60px;
    background:white;
    margin-right:10px;
    


}

.header-right p.username{
    margin-right:50px;
    font-size:13px;
}

.header-right .fa{
    display:none;
}


@media (max-width:767px){
    header.dashboard-header img.site-logo {
        height: 120px;
       
      
    }
    header.dashboard-header{
        height:60px;
        
        
    }
    .header-right p.username{
        margin-right:30px;
        
    }
}

@media (max-width:650px){
    header.dashboard-header img.site-logo {
        height: 100px;
       
      
    }
}
@media (max-width:520px){
    header.dashboard-header img.site-logo {
        height: 90px;
       
      
    }
    header.dashboard-header{
        height:50px;
        
        
    }
    .header-right .fa{
        display:block;
        font-size:20px;
    }
}


@media (max-width:360px){
    header.dashboard-header{
        padding:10px 5px;
        
        
    }
    header.dashboard-header img.site-logo {
        height: 70px;
       
      
    }
    .header-right p.username{
        margin-right:30px;
        
    }
    .header-right .fa{
        display:block;
        font-size:20px;
        margin-right:10px;
    }
}

@media (max-width:270px){
    header.dashboard-header{
        height:40px;
        
        
    }
    header.dashboard-header img.site-logo {
        height: 60px;
       
      
    }
    .header-right p.username{
        margin-right:20px;
        font-size:11px;
        display:none;
    }
}
section.popups{
    position: fixed;
    z-index:5;
    /* top:0; */
    background: white;
    width:100%;
    opacity:1;
    height:100%;
    bottom:0;
    display:none;
    justify-content: center;
    align-items: center;
}

section.show-blurry-pop{
    display:flex;
    background:#7339b7;
    animation:show-blur 0.5s ease-in;
}


@keyframes show-blur{
    0%{
        opacity: 0;
    }

    50%{
        opacity: 0.3;

    }

    75%{
        opacity: 0.5;

    }

    100%{
        opacity: 0.7;

    }
}

div.slowly-show-popups{
    animation:slowly-show-popups 0.6s ease-in;
}

@keyframes slowly-show-popups{
    0%{
        opacity: 0;
    }

    50%{
        opacity: 0.3;

    }

    75%{
        opacity: 0.7;

    }

    100%{
        opacity: 1;

    }
}
div.tdetails{
    border:1px solid white;
    height:90%;
    width:50%;
    /* padding: 10px; */
    display:flex;
    flex-direction:column;
    justify-content:normal;
    align-items:center;
    overflow-y: scroll;
}


div.tdetails .right p{
    padding:10px 10px;
    background: #8240b4;
    font-size:16px;
    margin:10px 0;
    text-align:center;
    /* border-radius: 10px; */
}
div.tdetails .right p.show-on-mobile{
    display:none;
}
div.tdetails .left{
    display: flex;
    
}

div.tdetails .left p{
    padding:15px 15px;
    background: #8240b4;
    font-size:16px;
    margin:10px 10px;
    text-align:center;
    border-radius:5px;
    cursor:pointer;
}

div.tdetails .left p{
    background: #f85d5d;
    text-transform:uppercase;
}
@media (max-width:850px){
    div.tdetails{
        
        width:60%;
      
    }
    div.tdetails .right{
        width:70%;
    }
}

@media (max-width:767px){
    div.tdetails .left p{
        font-size:14px;
        padding:10px 10px;
    }
}

@media (max-width:650px){
    div.tdetails .right{
        width:80%;
    }
}

@media (max-width:520px){
    div.tdetails{
        
        width:70%;
      
    }
    div.tdetails .right p{
        
        font-size:14px;
        padding:10px 5px;
        margin:15px 0;  
    }
}


@media (max-width:360px){
    div.tdetails .right p.show-on-mobile{
        display:block;
    }
      div.tdetails .right p{
        
        font-size:12px;
        padding:10px 5px;
          
    }
    div.tdetails{
        
        width:80%;
      
    }
}

@media (max-width:270px){
    
     
    div.tdetails{
        
        width:90%;
      
    }
    div.tdetails .left p{
        /* font-size:12px; */
        /* padding:11px 6px; */
    }
}
div.loading{
    
    
    
}

div.loading .fa{
    animation: rotate 1.5s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
    font-size:50px;
    /* color:white; */
}

@keyframes rotate{
    from{
        transform: rotateZ(0deg);
    color:white;

    }

    /* 50%{
        
    color:#b856a9;

    } */

    to{
        transform: rotateZ(360deg);
        color:#b856a9;
        font-size:60px;

    }
}
section.payments{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:100vh;
    width:100%;
    /* background:#8340b5; */
    

}

.mini-page{
   
    background:#1e0338;
    width:50%;
    height:60%;
    /* color:#1e0338; */
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius:10px;
    /* margin:auto; */
}

.mini-page h4{
    margin-bottom:5px;
}

.mini-page div.payment-system div{
    border: 1px solid #8340b5;
    padding:20px 15px;
    margin-bottom:20px;
    border-radius:10px;
    display: flex;
    color:#d764a3;
    font-size:20px;
    align-items:center;
    
}

.mini-page div.payment-system div p{
    
    margin-left:10px;
}

.mini-page div.form{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mini-page div.form form div{
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.mini-page div.form form div input{
    outline:none;
    background:transparent;
    padding:10px 15px;
    color:white;
    border:1px solid #8340b5;
    margin:10px 0;
    border-radius:10px;
}

.mini-page div.form div.buttons{
    display: flex;
}
.mini-page div.form div.buttons p{
    background:#8340b5;
    padding:10px 15px;
    margin-top:10px;
    text-transform: uppercase;
    cursor:pointer;
    border-radius:5px;
}

.mini-page div.form p:hover{
    transform:scale(0.95);
}

.mini-page div.form div.buttons p:nth-of-type(2){
    background: #613232;
    margin-left:10px;
}

@media (max-width:767px){
    .mini-page{
       
        width:60%;
        height:60%;
       
    }
    
.mini-page div.payment-system div{
   
    padding:10px 15px;
    margin-bottom:20px;
    
    font-size:15px;
   
    
}

.mini-page div.form form div label{
    font-size:13px;
}
.mini-page div.form div.buttons p{
    font-size:13px;
}
}

@media (max-width:520px){
    .mini-page{
       
        width:80%;
        /* height:60%; */
        height:70%;
    }

   
}

@media (max-width:270px){
    .mini-page{
       
        width:90%;
        /* height:60%; */
       
    }

    .mini-page div.form div.buttons p{
        font-size:12px;
    }
}


div.dashboard{
    position: relative;
    /* padding: 0 20px; */
    height:100vh;
    overflow-x:hidden;
    
    width: 100%;
}



/* MAIN-PAGE */

section.mainpage{
    position: absolute;
    left:6%;
    top:5%;
   
    padding: 5% 5%;
    height: 100%;
    width: 94%;
    /* background:white; */
}


section.maximize-mainpage{
    transition:all 0.5s ease-in-out;    
left: 12%;

}
@media (max-width:650px){
    section.mainpage{
       
       
        padding: 7% 5%;
        
        /* background:white; */
    }
}
@media (max-width:767px){
    section.maximize-mainpage{
        transition:all 0.5s ease-in-out;    
    left: 18%!important;
    
    }
}

@media (max-width:520px){
    section.mainpage{
        
        left:0;
        /* padding: 10% 5%; */
        width: 100%;
        /* background:white; */
    }
    section.maximize-mainpage{
         
    left:0!important;
    
    }
}

*{
  padding:0;
  margin: 0;
  box-sizing: border-box;
  transition:all 0.2s ease-in-out;
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Poppins', sans-serif  ;

}
*::selection{
  background:#9E9E9E ;
}

body{
  background: #1e0338;
  color:white;
}


section.admin-dashboard{
    position: relative;
    /* padding: 0 20px; */
    height:100vh;
    overflow-x:hidden;
    
    width: 100%;
}



/* MAIN-PAGE */

section.admin-main-page{
    position: absolute;
    left:6%;
    top:5%;
   
    padding: 5% 5%;
    height: 100%;
    width: 94%;
    /* background:white; */
}


section.maximize-mainpage{
    transition:all 0.5s ease-in-out;    
left: 12%;

}
@media (max-width:650px){
    section.admin-main-page{
       
       
        padding: 7% 5%;
        
        /* background:white; */
    }
}

@media (max-width:767px){
    section.maximize-mainpage{
        transition:all 0.5s ease-in-out;    
    left: 18%!important;
    
    }
}

@media (max-width:520px){
    section.admin-main-page{
        
        left:0;
        /* padding: 10% 5%; */
        width: 100%;
        /* background:white; */
    }
    section.maximize-mainpage{
         
    left:0!important;
    
    }
}



header.admin-header{
    position:fixed;
    z-index:5;
    background-color:#1e0338;
    right: 0;
    left: 0;
    height:70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:15px 20px;
    border-bottom:1px solid white;
    
}

header.admin-header img.site-logo {
    height: 150px;
    background:transparent;
    /* color: aquamarine; */
}


header.admin-header div.header-right{
    display: flex;
    align-items: center;
    
}

header.admin-header div.header-right div{
    border-color:white;
}
img.profile-picture{
    height: 60px;
    background:white;
    margin-right:10px;
    


}

header.admin-header div.header-right p.username{
    margin-right:50px;
    font-size:13px;
}

header.admin-header div.header-right .fa{
    display:none;
}

@media (max-width:850px){
    header.admin-header img.site-logo {
        height: 110px;
       
      
    }
    header.admin-header{
        height:60px;
        
        
    }
    .header-right p.username{
        margin-right:30px;
        
    }
}

@media (max-width:650px){
    header.admin-header img.site-logo {
        height: 100px;
       
      
    }
}
@media (max-width:520px){
    header.admin-header img.site-logo {
        height: 90px;
       
      
    }
    header.admin-header{
        height:50px;
        
        
    }
    header.admin-header div.header-right .fa-bars{
        display:block;
        font-size:20px;
    }
}


@media (max-width:360px){
    header.admin-header{
        padding:10px 5px;
        
        
    }
    header.admin-header img.site-logo {
        height: 70px;
       
      
    }
    .header-right p.username{
        margin-right:30px;
        
    }
    header.admin-header div.header-right .fa-bars{
        display:block;
        font-size:20px;
        margin-right:10px;
    }
}

@media (max-width:270px){
    header.admin-header{
        height:40px;
        
        
    }
    header.admin-header img.site-logo {
        height: 60px;
       
      
    }
    .header-right p.username{
        margin-right:20px;
        font-size:11px;
        display:none;
    }
}

/* SIDEBAR */

section.admin-sidebar{
    position: fixed;
    background:#1e0338;
    top:70px;
    bottom:0;
    left:0;
    border-right:1px solid white;
    display: flex;
    flex-direction: column;
    /* align-items:center; */
    /* text-align:center; */
    padding:15px 10px;
    font-size: 13px;;
    color:#8e819b;
    width:5%;
    /* height:100vh; */
    /* transition: all 1s ease-in-out; */
    
}


section.maximize-sidebar{
    transition: all 0.5s ease-in-out;
    width: 11%;
    border-right:1px solid #7339b8;
}

section.admin-sidebar .direction{
   
    justify-content:flex-end;
    margin:0;
    
    
    border-radius:0;
}



section.admin-sidebar .direction .fa{
font-size:30px;
/* position: absolute; */
top:10px;
right:5px;
}

section.admin-sidebar .direction .fa-chevron-circle-left{
    display: none;
}


section.admin-sidebar .direction  .show-chev{
    display:block;
}

section.admin-sidebar .direction .fa:hover{
    color: #9748b0;
    cursor:pointer;
    
}
section.admin-sidebar p{
    
    margin-left: 7px;;
}


section.admin-sidebar div p.description{
    /* transition: all 1s ease-in-out; */

    /* transition: all 0.5s ease-in; */
    display: none;
}

section.admin-sidebar div p.slowly-show-description{
    display: flex;
        opacity: 1; 
        /* margin-left: 500px; */
    animation: slowly-show 0.5s ease-in;
}

@keyframes slowly-show{
    0%{
        display: none;
        opacity: 0;
        
        
    }

    50%{
        display: none;
        opacity: 0.5;
       

    }
    100%{
        display: flex;
        opacity: 1; 
        
    }
}


section.admin-sidebar div.overview:hover,
section.admin-sidebar div.user:hover,
section.admin-sidebar div.transactions-div:hover,
section.admin-sidebar div.payments:hover,
section.admin-sidebar div.data:hover,
section.admin-sidebar div.cable-tv:hover,
section.admin-sidebar div.settings:hover{
    background: #9748b0;
    cursor:pointer;
    color:white;
    
   
}

section.admin-sidebar div.logout:nth-child(2):hover{
    background: red;
    cursor:pointer;
    color:white;
}

.side-bar-active{
    background: #9748b0;
    cursor:pointer;
    color:white;
    
}


section.admin-sidebar div{
    
    margin:10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px 7px;
    border-radius: 10px;;
    
}

section.admin-sidebar div{
    align-self: center;
    width: 100%;
}



section.admin-sidebar .sidebar-footer{
    padding:0;
    flex-direction: column;
    margin-top:120px;
}

section.admin-sidebar .sidebar-footer div:hover{
    background-color: white;
    cursor:pointer;
}


@media (max-width:1050px){
    section.admin-sidebar{
       
        
        /* overflow-y: scroll; */
        /* transition: all 1s ease-in-out; */
        
    }
    section.admin-sidebar{
        font-size: 13px;
       
        
    }
   
    section.admin-sidebar .direction .fa{
        font-size:25px;
    
        
        }
        section.admin-sidebar p{
    
            margin-left: 5px;;
        }
}

@media (max-width:920px){
    section.admin-sidebar{
        width:7%;
       
        
    }
    section.admin-sidebar .direction .fa{
        font-size:20px;
    
        
        }
}
@media (max-width:850px){
        

section.admin-sidebar{
    top:50px;
   
    
}
    section.admin-sidebar{
        width:7%;
       
        
    }
    section.admin-sidebar .direction .fa{
        font-size:20px;
    
        
        }
}
@media (max-width:650px){
    section.admin-sidebar{
       
        top:60px;
        overflow-y: scroll;
        /* transition: all 1s ease-in-out; */
        
    }
    section.maximize-sidebar{
            
        /* background-color: pink; */
      width: 18%!important;
      
         
      }
      section.admin-sidebar{
        font-size: 11px;
       
        
    }
    
}

@media (max-width:520px){
    section.admin-sidebar .hide-on-mobile{
        display: none;
        
    }
    section.admin-sidebar{
       
        top:50px;
        width:10%;
        z-index:1;
        left:-10%;
        position:fixed;
        /* opacity: 0; */
        /* display:none; */
        
        
    }
   

    section.show-sidebar{
        
        display:flex;
        width:23%!important;
        z-index:5;
        left:0;
        animation:animate-sidebar .5s ease-in-out forwards;
    }

    @keyframes animate-sidebar {
        from{
            /* width:10%; */
            left:-10%;
            /* display:none; */
            opacity:0;
        }

        to{
            display:flex;
            opacity:1;
            left:0;
        }
    }
}

@media (max-width:480px){
    section.show-sidebar{
        
        
        width:25%!important;
        
    }
}
@media (max-width:360px){
    section.show-sidebar{
        
        
        width:33%!important;
        
    }
}
@media (max-width:270px){
    section.show-sidebar{
        
        
        width:50%!important;
        
    }
    section.admin-sidebar{
       
        top:40px;
        
        /* transition: all 1s ease-in-out; */
        
    }
}
section.admin-overview .grid-wrapper{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding:20px 0;
    /* justify-content:center; */
    width:100%;
    grid-gap: 20px;
}



@media (max-width:480px){
    section.admin-overview .grid-wrapper{
        grid-template-columns: repeat(1,1fr);
        display: block;
        
        
    }
    section.admin-overview .grid-wrapper div{
        margin:10px 0;
    }
}
div.total-number-of-users{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:50px 10px;
}

div.total-number-of-users div.value{
    /* flex:2; */
    /* border:10px solid #f9a9c6; */
    border-radius: 50%;
    /* width:30px; */
    /* padding:25px 25px; */
    font-size:25px;
    margin-right:10px;
    color:#d2558f;
}

div.total-number-of-users >p{
    font-weight:bold;
}

@media (max-width:650px){
    div.total-number-of-users >p{
        font-size:15px;
    }
}
div.total-number-of-transactions-admin{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:50px 25px;
}

div.total-number-of-transactions-admin div.value{
    /* flex:2; */
    /* border:10px solid #f9a9c6; */
    /* border-radius: 50%; */
    /* width:30px; */
    /* padding:25px 25px; */
    font-size:25px;
    margin-right:10px;
    color:#d2558f;
}

div.total-number-of-transactions-admin >p{
    font-weight:bold;
}

@media (max-width:650px){
    div.total-number-of-transactions-admin >p{
        font-size:15px;
    }
}
div.total-transactions-admin{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:50px 10px;
}

div.total-transactions-admin div.value{
    /* flex:2; */
    /* border:10px solid #f9a9c6; */
    border-radius: 100%;
    /* width:40%; */
    /* padding:25px 25px; */
    margin-right:10px;
    color:#d2558f;
    font-size:30px;
}
div.total-transactions-admin div.value h5{
    color:white;
    font-weight: bolder;
}

div.total-transactions-admin >p{
    font-weight:bold;
    font-size:20px;
}

@media (max-width:650px){
    div.total-transactions-admin div.value{
        font-size:25px;
    }
    div.total-transactions-admin >p{
    
    font-size:15px;
}
}
div.lastloggedusers{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
   display:flex;
   flex-direction:column;
   padding:2% 5%;
    margin:10px auto;
    
    /* grid-column:1/4; */
}

div.lastloggedusers h3{
    font-size:20px;
    font-weight:bold;
    margin-bottom:15px;
}

div.lastloggedusers table{
    /* text-align:center; */
}

div.lastloggedusers .table-label ul{
    display:flex;
    justify-content: space-between;
    margin-bottom:10px;
    padding:0 30px;
    /* align-items: center; */

}
div.lastloggedusers .table-label ul li{
    list-style:none
}
div.lastloggedusers .table-data ul:hover{
    cursor:pointer;
    transition:all 0.3s ease-in-out;
    border-color:#b454aa;
    background:#b454aa;
    transform:scale(1.05)
}
div.lastloggedusers .table-data ul{
    display:flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    padding:15px 20px;
    border-radius:15px;
    margin: 10px 0;
}
div.lastloggedusers .table-data ul li{
    list-style:none
}

div.lastloggedusers .status{
    text-align:center;
    padding:5px 5px;
    border-radius:10px;
    width: 100px;
}
@media (max-width:767px){
    div.lastloggedusers .table-label ul li{
        font-size:15px;
    }
    div.lastloggedusers .table-data ul li{
        font-size:15px;
    }
}

@media (max-width:650px){
    div.lastloggedusers h3{
        font-size:17px;
        
    }
    div.lastloggedusers .table-label ul li{
        font-size:14px;
    }
    div.lastloggedusers .table-data ul li{
        font-size:13px;
    }
}

@media (max-width:480px){
    div.lastloggedusers{
      
       padding:2% 2%;
        
        
        /* grid-column:1/4; */
    }
    div.lastloggedusers .table-label ul li{
        font-size:13px;
    }
    div.lastloggedusers .table-label ul{
       
        padding:0 15px;
        /* align-items: center; */
    
    }
    div.lastloggedusers .table-data ul{
    
    padding:15px 10px;
   
}
div.lastloggedusers .status{
   
    padding:5px 1px!important;
    width: 70px!important;
}
}

@media (max-width:360px){
    div.lastloggedusers .hide-on-mobile{
        display:none;
    }
    div.lastloggedusers .table-data ul{
    
        padding:11px 5px;
       
    }
    div.lastloggedusers .table-label ul li{
       font-size:10px;
    }
}
div.admin-wallet-balance{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:50px 10px;
}


div.admin-wallet-balance div.value{
     /* flex:2; */
    /* border:10px solid #f9a9c6; */
    border-radius: 100%;
    /* width:40%; */
    /* padding:25px 25px; */
    margin-right:10px;
    color:#d2558f;
    font-size:30px;
   
}

div.admin-wallet-balance div.value h5{
    color:white;
    font-weight: bolder;
}

div.admin-wallet-balance >p{
    font-weight:bold;
    font-size:20px;
}

@media (max-width:650px){
    div.admin-wallet-balance >p{
        
        font-size:15px;
    }
    div.admin-wallet-balance div.value{
       
       font-size:25px;
   }
}

section.admin-users .table{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
   display:flex;
   flex-direction:column;
   padding:2% 5%;
    margin:20px auto;
    font-family: 'Roboto Mono', monospace;
    /* grid-column:1/4; */
}
section.admin-users .header{
    display:flex;
    justify-content: space-between;
    align-items:baseline;
}
section.admin-users h3{
    font-size:30px;
    font-weight:bold;
    margin-bottom:25px;
}
section.admin-users input{
    outline:none;
    border:1px solid white;
    background:transparent;
    /* height:30px; */
    border-radius:5px;
    padding:10px 15px;
    color:white;
}

section.admin-users table{
    /* text-align:center; */
}

section.admin-users .table-label ul{
    display:flex;
    justify-content: space-between;
    margin-bottom:10px;
    padding:0 30px;
    /* align-items: center; */

}
section.admin-users .table-label ul li{
    list-style:none
}

section.admin-users .table-data ul{
    display:flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    padding:15px 20px;
    border-radius:15px;
    margin: 10px 0;
}

section.admin-users .table-data ul:hover{
    cursor:pointer;
    transition:all 0.3s ease-in-out;
    border-color:#b454aa;
    background:#b454aa;
    transform:scale(1.05)
}
section.admin-users .table-data ul li{
    list-style:none
}

section.admin-users .status{
    text-align:center;
    padding:5px 5px;
    border-radius:10px;
    width: 100px;
}
@media (max-width:767px){
    section.admin-users .table-label ul{
       
        padding:0 15px;
        /* align-items: center; */
    
    }
    section.admin-users .table-label ul li{
        font-size:15px;
    }
    section.admin-users .table-data ul li{
        font-size:13px;
    }
}

@media (max-width:480px){
    section.admin-users .status{
       
        width: 70px;
    }
    section.admin-users h3{
        font-size:25px;
        
    }
    section.admin-users .table-data ul{
        
        padding:15px 5px;
      
    }
    section.admin-users .table{
       
       padding:2% 2%;
       
        /* grid-column:1/4; */
    }
    section.admin-users input{
        
        padding:5px 15px;
        
    }
}


@media (max-width:360px){
    section.admin-users .status{
        padding:5px 2px;
        width: 50px;
    }
    section.admin-users .hide-on-mobile{
       display:none;
    
    }
    section.admin-users .table-label ul{
       
        padding:0 13px;
        /* align-items: center; */
    
    }
    section.admin-users .table-label ul li{
        font-size:13px;
     
     }
}

@media (max-width:270px){
    section.admin-users h3{
        font-size:20px;
        
    }
    section.admin-users input{
        width:130px;
        padding:5px 10px;
        
    }
    section.admin-users .table-label ul{
       
        padding:0 5px;
        /* align-items: center; */
    
    }
    section.admin-users .table-data ul li{
        font-size:10px;
    }
    section.admin-users .status{
       
        width: 40px;
    }
}
section.admin-transactions-page .table{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
   display:flex;
   flex-direction:column;
   padding:2% 5%;
    margin:20px auto;
    font-family: 'Roboto Mono', monospace;
    /* grid-column:1/4; */
}
section.admin-transactions-page .header{
    display:flex;
    justify-content: space-between;
    align-items:baseline;

}
section.admin-transactions-page h3{
    font-size:30px;
    font-weight:bold;
    margin-bottom:25px;
}
section.admin-transactions-page .header div{
    display:flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: space-between;
}
section.admin-transactions-page input{
    outline:none;
    border:1px solid white;
    background:transparent;
    /* height:30px; */
    margin-left:10px;
    border-radius:5px;
    padding:10px 15px;
    color:white;
}
section.admin-transactions-page .table div.pagination-box{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top:10px;
}

section.admin-transactions-page .table div.pagination-box div{
    margin:0 5px;
    display:flex;
    flex-direction: row;
}
section.admin-transactions-page .table div.pagination-box div:nth-of-type(2) p{
    margin-right:5px;
    
}
section.admin-transactions-page .table div.pagination-box .fa{
    font-size:30px;
}

section.admin-transactions-page .table div.pagination-box .fa:hover{
    cursor:pointer;
    color:#b454aa;
}
section.admin-transactions-page .table-label ul{
    display:flex;
    justify-content: space-between;
    margin-bottom:10px;
    padding:0 30px;
    /* align-items: center; */

}
section.admin-transactions-page .table-label ul li{
    list-style:none
}

section.admin-transactions-page .table-data ul{
    display:flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    padding:15px 20px;
    border-radius:15px;
    margin: 10px 0;
}

section.admin-transactions-page .table-data ul:hover{
    cursor:pointer;
    transition:all 0.3s ease-in-out;
    border-color:#b454aa;
    background:#b454aa;
    transform:scale(1.05)
}
section.admin-transactions-page .table-data ul li{
    list-style:none
}

section.admin-transactions-page .status{
    text-align:center;
    padding:5px 5px!important;
    border-radius:10px;
    width: 100px!important;
}
@media (max-width:767px){
    section.admin-transactions-page h3{
        font-size:25px;
        
    }
    section.admin-transactions-page input{
        width:150px;
        padding:7px 15px;
        
    }
}

@media (max-width:650px){
    section.admin-transactions-page .table-label ul{
        
        padding:0 15px;
        /* align-items: center; */
    
    }
    section.admin-transactions-page .table-data ul li{
        font-size:13px;
    }
}

@media (max-width:520px){
    section.admin-transactions-page h3{
        font-size:20px;
        
    }
    section.admin-transactions-page input{
        width:120px;
        padding:7px 15px;
        
    }
    section.admin-transactions-page .table{
        
       padding:2% 2%;
        
    }
    section.admin-transactions-page .status{
        
        padding:5px 2px!important;
        
        width: 70px!important;
    }
    section.admin-transactions-page .table-data ul{
      
        padding:10px 10px;
        
    }
    section.admin-transactions-page .table div.pagination-box .fa{
        font-size:25px;
        
    }
    section.admin-transactions-page .table div.pagination-box div{
        font-size:15px;
    }
}

@media (max-width:480px){
    section.admin-transactions-page .hide-on-mobile{
        display: none;
    }
}

@media (max-width:360px){
    section.admin-transactions-page .header{
        display:block;
        
    
    }
    section.admin-transactions-page input{
        margin:0;
    }
}
section.admin-payment-view .table{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
   display:flex;
   flex-direction:column;
   padding:2% 5%;
    margin:20px auto;
    font-family: 'Roboto Mono', monospace;
    /* grid-column:1/4; */
}

section.admin-payment-view h3{
    font-size:30px;
    font-weight:bold;
    margin-bottom:25px;
}
section.admin-payment-view .header{
    display:flex;
    justify-content: space-between;
    align-items:baseline;
}
section.admin-payment-view .header div{
    display:flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: space-between;
    
}
section.admin-payment-view input{
    outline:none;
    border:1px solid white;
    background:transparent;
    /* height:30px; */
    margin-left:10px;
    border-radius:5px;
    padding:10px 15px;
    color:white;
}

section.admin-payment-view .table-label ul{
    display:flex;
    justify-content: space-between;
    margin-bottom:10px;
    padding:0 30px;
    /* align-items: center; */

}
section.admin-payment-view .table-label ul li{
    list-style:none
}

section.admin-payment-view .table-data ul{
    display:flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    padding:15px 20px;
    border-radius:15px;
    margin: 10px 0;
}

section.admin-payment-view .table-data ul:hover{
    cursor:pointer;
    transition:all 0.3s ease-in-out;
    border-color:#b454aa;
    background:#b454aa;
    transform:scale(1.05)
}
section.admin-payment-view .table-data ul li{
    list-style:none
}

section.admin-payment-view .status{
    text-align:center;
    padding:5px 5px!important;
    border-radius:10px;
    width: 100px!important;
}
@media (max-width:650px){
    section.admin-payment-view input{
        width:150px;
        padding:10px 15px;
        
    }
    section.admin-payment-view h3{
        font-size:25px;
        
    }
}

@media (max-width:520px){
    section.admin-payment-view .table-data ul li{
        font-size:13px;
    }
}

@media (max-width:480px){
    section.admin-payment-view .table-label ul li{
        font-size:15px;
    }
    section.admin-payment-view .table-data ul{
        
        padding:15px 10px;
        
    }
    section.admin-payment-view .table-label ul{
        
        padding:0 10px;
        
    }
    section.admin-payment-view .header{
        display:block;
        
    
    }
    section.admin-payment-view input{
        margin:0;
    }
}

@media (max-width:360px){
    section.admin-payment-view .status{
       
        padding:5px 2px!important;
        
        width: 70px!important;
    }
    section.admin-payment-view .table{
       
       padding:2% 2%;
      
    }
     section.admin-payment-view .table-data ul li{
        font-size:10px;
    }
}
@media (max-width:270px){
    section.admin-payment-view input{
        width:120px;
    }
}
section.add-edit-data h3{
    display:flex;
    font-size:30px;
    /* margin-bottom:20px; */
    width:70%;
    margin:20px auto;
}

section.add-edit-data > div{
    display:flex;
    flex-direction:column;
    border:3px solid white;
    height:100%;
    width:80%;
    margin:auto;
    padding:2%;
    align-items:center;
    border-radius:10px;
}

section.add-edit-data > div form{
    display:flex;
    flex-direction: column;
}



section.add-edit-data > div form div{
    margin:5px 0;
}


section.add-edit-data > div form >div{
    display: flex;
    flex-direction: column;
}

section.add-edit-data > div form label{
    margin-bottom:10px;
}

section.add-edit-data > div form >div select option{
    color: white;
    background:#1e0338;
    border:none;
    outline:none;
    padding:3px 0;
}

section.add-edit-data > div form input,
section.add-edit-data > div form >div select{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #7e3eb5;
}

section.add-edit-data > div form div.floating-inputs{
    display: flex;
    flex-direction:row;
}

section.add-edit-data > div form div.floating-inputs .inputs input{
    margin:0 5px;
}

section.add-edit-data > div form div.floating-inputs .options{
    display:flex;
    align-items: center;
    margin-left:5px;
}

section.add-edit-data > div form div.floating-inputs .options p{
    background: #7e3eb5;
    padding:5px 10px;
    border-radius:5px;
    cursor:pointer;
}

@media (max-width:650px){
    section.add-edit-data h3{
        
        font-size:30px;
        
        width:80%;
       
    }
    section.add-edit-data > div{
       
        width:90%;
        
    }
}

@media (max-width:520px){
    section.add-edit-data h3{
        
        font-size:25px;
        
        width:80%;
       
    }
    section.add-edit-data > div form{
        font-size:15px;
    }
    section.add-edit-data > div form input,
section.add-edit-data > div form >div select{
    font-size:10px;
}

}

@media (max-width:360px){
    section.add-edit-data > div form div.floating-inputs .inputs input{
        margin:0 2px;
    }
    section.add-edit-data h3{
        
        font-size:18px;
        
        width:80%;
       
    }
    section.add-edit-data > div{
       
        width:100%;
        
    }
    section.add-edit-data > div form{
        font-size:13px;
    }
    section.add-edit-data > div form input,
section.add-edit-data > div form >div select{
    font-size:9px;
}


}
section.cable-tv-admin h3{
    display:flex;
    font-size:30px;
    text-align: center;
    /* margin-bottom:20px; */
    width:70%;
    margin:20px auto;
}

section.cable-tv-admin > div{
    display:flex;
    flex-direction:column;
    border:3px solid white;
    height:100%;
    width:80%;
    margin:auto;
    padding:2%;
    align-items:center;
    border-radius:10px;
}

section.cable-tv-admin > div form{
    display:flex;
    flex-direction: column;
}



section.cable-tv-admin > div form div{
    margin:5px 0;
}


section.cable-tv-admin > div form >div{
    display: flex;
    flex-direction: column;
}

section.cable-tv-admin > div form label{
    margin-bottom:10px;
}

section.cable-tv-admin > div form >div select option{
    color: white;
    background:#1e0338;
    border:none;
    outline:none;
    padding:3px 0;
}

section.cable-tv-admin > div form input,
section.cable-tv-admin > div form >div select{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #7e3eb5;
}

section.cable-tv-admin > div form div.floating-inputs{
    display: flex;
    flex-direction:row;
}

section.cable-tv-admin > div form div.floating-inputs .inputs input{
    margin:0 5px;
}

section.cable-tv-admin > div form div.floating-inputs .options{
    display:flex;
    align-items: center;
    margin-left:5px;
}

section.cable-tv-admin > div form div.floating-inputs .options p{
    background: #7e3eb5;
    padding:5px 10px;
    border-radius:5px;
    cursor:pointer;
}

@media (max-width:650px){
    section.cable-tv-admin h3{
        
        font-size:30px;
        
        width:80%;
       
    }
    section.cable-tv-admin > div{
       
        width:90%;
        
    }
}

@media (max-width:520px){
    section.cable-tv-admin h3{
        
        font-size:20px;
        
        width:90%;
       
    }
    section.cable-tv-admin > div form{
        font-size:15px;
    }
    section.cable-tv-admin > div form input,
section.cable-tv-admin > div form >div select{
    font-size:10px;
}

}

@media (max-width:360px){
    section.cable-tv-admin > div form div.floating-inputs .inputs input{
        margin:0 2px;
    }
    section.cable-tv-admin h3{
        
        font-size:14px;
        
        width:100%;
       
    }
    section.cable-tv-admin > div{
       
        width:100%;
        
    }
    section.cable-tv-admin > div form{
        font-size:13px;
    }
    section.cable-tv-admin > div form input,
section.cable-tv-admin > div form >div select{
    font-size:9px;
}


}
section.popups-admin{
    position: fixed;
    z-index:5;
    top:0;
    background: white;
    width:100%;
    opacity:0.9;
    /* height:100vh; */
    bottom:0;
    display:none;
    justify-content: center;
    align-items: center;
}

section.show-blurry-pop{
    display:flex;
    background:#000000;
    animation:show-blur 0.5s ease-in;
}


@keyframes show-blur{
    0%{
        opacity: 0;
    }

    50%{
        opacity: 0.3;

    }

    75%{
        opacity: 0.5;

    }

    100%{
        opacity: 0.7;

    }
}

div.slowly-show-popups-admin{
    animation:slowly-show-popups-admin 0.6s ease-in;
}

@keyframes slowly-show-popups-admin{
    0%{
        opacity: 0;
    }

    50%{
        opacity: 0.3;

    }

    75%{
        opacity: 0.7;

    }

    100%{
        opacity: 1;

    }
}
div.userdetails{
    border:1px solid white;
    height:60%;
    width:40%;
    padding: 20px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    
}


div.userdetails .right p{
    padding:15px 15px;
    background: #8240b4;
    font-size:16px;
    margin:10px 0;
    text-align:center;
}
div.userdetails .right h4{
    color:pink;
    text-align:center;
}
div.userdetails .left{
    display: flex;
    
}

div.userdetails .left p{
    padding:15px 15px;
    background: #8240b4;
    font-size:16px;
    margin:10px 10px;
    text-align:center;
    border-radius:5px;
    cursor:pointer;
}

div.userdetails .left p:nth-of-type(2){
    background: #f85d5d;

}
@media (max-width:1050px){
    div.userdetails{
       overflow-y:scroll;
        height:80%;
        width:40%;
        padding: 20px;
       
        align-items:center;
        
    }
}
@media (max-width:650px){
    div.userdetails{
       overflow-y:scroll;
        height:80%;
        width:60%;
        padding: 20px;
       
        
        
    }
}

@media (max-width:520px){
    div.userdetails{
       overflow-y:scroll;
        height:80%;
        width:60%;
        padding: 15px;
       
        
        
    }
    
div.userdetails .right p{
    padding:10px 10px;
    
    font-size:14px;
    
}
div.userdetails .left p{
    
    font-size:13px;
   
    
}
}
@media (max-width:520px){
    div.userdetails{
       overflow-y:scroll;
        height:80%;
        width:70%;
        padding: 15px;
       
        
        
    }

}

@media (max-width:360px){
    div.userdetails{
       overflow-y:scroll;
        height:80%;
        width:90%;
        padding: 15px;
       
        
        
    }

}
@media (max-width:270px){
    div.userdetails{
       
        width:95%;
        
       
        
        
    }
    div.userdetails .right p{
    
        font-size:11px;
       
        
    }
}
div.admin-loading .fa{
    animation: rotate 1.5s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
    font-size:50px;
    /* color:white; */
}

@keyframes rotate{
    0%{
        transform: rotateZ(0deg);
    color:white;

    }

    /* 50%{
        
    color:#b856a9;

    } */

    100%{
        transform: rotateZ(360deg);
        color:#b856a9;
        font-size:60px;

    }
}
div.admin-tdetails{
    border:1px solid white;
    height:80%;
    width:50%;
    /* padding:0 10px; */
    display:flex;
    flex-direction:column;
    justify-content:normal;
    align-items:center;
    overflow-y: scroll;
    text-align:center;
}

div.admin-tdetails .right{
    padding:0 10px;
}
div.admin-tdetails .right p{
    padding:5px 5px;
    background: #8240b4;
    font-size:16px;
    margin:10px 0;
    /* text-align:center; */
}

div.admin-tdetails .left{
    display: flex;
    
}

div.admin-tdetails .left p{
    padding:10px 10px;
    background: #8240b4;
    font-size:16px;
    margin:10px 10px;
    text-align:center;
    border-radius:5px;
    cursor:pointer;
}

div.admin-tdetails .left p:nth-of-type(2){
    background: #f85d5d;

}
@media (max-width:520px){
    div.admin-tdetails{
        
        width:70%;
        
    }
    div.admin-tdetails .right p{
        
        font-size:14px;
        
    }
    div.admin-tdetails .left p{
        
        font-size:14px;
       
    }
}

@media (max-width:480px){
    div.admin-tdetails{
        
        width:90%;
        
    }
}
@media (max-width:270px){
    div.admin-tdetails{
        
        width:95%;
        
    }
    div.admin-tdetails .right p{
        
        font-size:14px;
        
    }
}
div.payment-details{
    border:1px solid white;
    height:70%;
    width:40%;
    padding: 20px;
    display:flex;
    text-align-last: center;
    flex-direction:column;
    justify-content:normal;
    align-items:center;
    overflow-y: scroll;
}


div.payment-details .right p{
    padding:15px 15px;
    background: #8240b4;
    font-size:16px;
    margin:10px 0;
    text-align:center;
}

div.payment-details .left{
    display: flex;
    
}

div.payment-details .left p{
    padding:15px 15px;
    background: #8240b4;
    font-size:16px;
    margin:10px 10px;
    text-align:center;
    border-radius:5px;
    cursor:pointer;
}

div.payment-details .left p:nth-of-type(2){
    background: #f85d5d;

}
@media (max-width:767px){
    div.payment-details{
        
        width:60%;
        
    }
}

@media (max-width:520px){
    div.payment-details{
        
        width:70%;
        
    }
    div.payment-details .right p{
        
        font-size:14px;
        
    }
    div.payment-details .left p{
        
        font-size:14px;
        
    }
    
}
@media (max-width:360px){
    div.payment-details{
        
        width:90%;
        
    }
   
    
}
div.data-details{
    border:2px solid white;
    height:60%;
    width:40%;
    padding: 20px;
    display:flex;
    flex-direction:column;
    justify-content:normal;
    align-items:center;
    border-radius:5px;
    overflow-y: scroll;
    /* background:#9045b1; */
}


div.data-details > div{
    display:flex;
    flex-direction:column;
    /* border:3px solid white; */
    height:100%;
    width:100%;
    margin:auto;
    padding:2%;
    align-items:center;
    border-radius:10px;
}


div.data-details form{
    display: flex;
    flex-direction: column;
    margin:auto;
    
    /* justify-content: center; */
    /* align-items: center; */
    width: 40%;
}

div.data-details form div{
    display:flex;
    flex-direction: column;
    margin:15px 0;
}

div.data-details form div label{
    margin-bottom:10px;
}


div.data-details form div input{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #9045b1;
}

div.data-details div.options{
    display:flex;
    flex-direction:row;
}

div.data-details p{
    
    /* text-align: center; */
    padding:10px 10px;
    background:#9548b0;
    /* width:20%; */
    cursor:pointer;
    margin:0 5px;
    border-radius:5px;
}

div.data-details p:hover{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}
@media (max-width:1050px){
    div.data-details{
        
        width:70%;
        padding: 15px;
        
        /* background:#9045b1; */
    }
}

@media (max-width:650px){
    div.data-details{
        
        width:80%;
        padding: 10px;
        
        /* background:#9045b1; */
    }
}

@media (max-width:520px){
    div.data-details{
        
        width:80%;
        padding: 5px;
        
        /* background:#9045b1; */
    }
    div.data-details > div{
        
        padding:2%;
        
    }
    div.data-details form{
       
        width: 60%;
    }
}

@media (max-width:480px){
    div.data-details form div label{
       font-size:15px;
    }
    div.data-details form div input{
      font-size:13px;
    }
    div.data-details div.options p{
    
       font-size:16px;
    }
}
@media (max-width:360px){
    div.data-details form{
       
        width: 70%;
    }
    div.data-details div.options p{
    
       font-size:14px;
    }
    div.data-details div.options p{
    
        padding:7px 7px;
     }
}

@media (max-width:270px){
    div.data-details form{
       
        width: 90%;
    }
    div.data-details{
        
        width:90%;
       
        
        /* background:#9045b1; */
    }
    
}
div.cable-details{
    border:2px solid white;
    height:60%;
    width:40%;
    padding: 20px;
    display:flex;
    flex-direction:column;
    justify-content:normal;
    align-items:center;
    border-radius:5px;
    overflow-y:scroll;
    /* background:#9045b1; */
}


div.cable-details > div{
    display:flex;
    flex-direction:column;
    /* border:3px solid white; */
    height:100%;
    width:100%;
    margin:auto;
    padding:2%;
    align-items:center;
    border-radius:10px;
}


div.cable-details form{
    display: flex;
    flex-direction: column;
    margin:auto;
    
    /* justify-content: center; */
    /* align-items: center; */
    width: 40%;
}

div.cable-details form div{
    display:flex;
    flex-direction: column;
    margin:15px 0;
}

div.cable-details form div label{
    margin-bottom:10px;
}


div.cable-details form div input{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #9045b1;
}

div.cable-details div.options{
    display:flex;
    flex-direction:row;
}

div.cable-details p{
    
    /* text-align: center; */
    padding:10px 10px;
    background:#9548b0;
    /* width:20%; */
    cursor:pointer;
    margin:0 5px;
    border-radius:5px;
}

div.cable-details p:hover{
    transition:all 0.3s ease-in-out;
    transform:scale(0.80)
}
@media (max-width:1050px){
    div.cable-details{
        
        width:70%;
        padding: 15px;
        
        /* background:#9045b1; */
    }
}

@media (max-width:650px){
    div.cable-details{
        
        width:80%;
        padding: 10px;
        
        /* background:#9045b1; */
    }
}

@media (max-width:520px){
    div.cable-details{
        
        width:80%;
        padding: 5px;
        
        /* background:#9045b1; */
    }
    div.cable-details > div{
        
        padding:2%;
        
    }
    div.cable-details form{
       
        width: 60%;
    }
}

@media (max-width:480px){
    div.cable-details form div label{
       font-size:15px;
    }
    div.cable-details form div input{
      font-size:13px;
    }
    div.cable-details div.options p{
    
       font-size:16px;
    }
}
@media (max-width:360px){
    div.cable-details form{
       
        width: 70%;
    }
    div.cable-details div.options p{
    
       font-size:14px;
    }
    div.cable-details div.options p{
    
        padding:7px 7px;
     }
}

@media (max-width:270px){
    div.cable-details form{
       
        width: 90%;
    }
    div.cable-details{
        
        width:90%;
       
        
        /* background:#9045b1; */
    }
    
}
section.forgot-password{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/v1606016777/get-started1_yhyuyd.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

section.forgot-password h1{
    font-size:30px;
    margin-bottom: 30px;
    line-height:20px;
    border:1px solid #1e0338;
    padding:15px 20px;
    animation:animate-h1 2s infinite;
}

@keyframes animate-h1{
    0%{
        border-left-color:#9849b0;
        /* color:pink; */
    }

    25%{
        border-top-color:#e16d9b;
    }

    50%{
        border-right-color:#9849b0;
        
    }

    75%{
        border-bottom-color:#e16d9b;
    }

    100%{
        border-color:#1e0338;
    }
}

section.forgot-password div.form{
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background:#000000ed;
    /* background: blue; */
    
}

section.forgot-password div.form .fa{
    color:#5d3b86;
}
section.forgot-password div.form h3{
    font-size:15px;
    margin-bottom: 10px;
    text-align: center;
}
section.forgot-password div.form h3.resetMsg{
    font-size:20px;
    margin-bottom: 10px;
    text-align: center;
    width:50%;
}

section.forgot-password div.form p{
    margin-top:10px;
    padding:10px 15px;
    background:#5d3b86;
    border-radius: 5px;
    font-size:15px;
}

section.forgot-password div.form p:hover{
    cursor:pointer;
    transition: all 0.5s ease-in;
    background-color: #6f42a5;
}
section.forgot-password form div{
    display: flex;
    align-items: center;
    border:1px solid white;
    padding:0 10px;
    border-radius:5px;
    margin:5px 0 15px 0;
    /* flex-direction: column; */
   
}

section.forgot-password form div input{
    background: transparent;
    outline: none;
    border:none;
    padding: 10px 15px;
    
    color: white;
}

@media (max-width:650px){
    section.forgot-password div.form h3.resetMsg{
        
        width:60%;
    }
}
@media (max-width:520px){
    section.forgot-password div.form h3.resetMsg{
        font-size:20px;
        width:80%;
    }
}
@media (max-width:360px){
    section.forgot-password h1{
        font-size:25px;
      
    }
    section.forgot-password div.form h3{
        font-size:13px;
        
    }
    section.forgot-password div.form h3.resetMsg{
        font-size:16px;
        width:80%;
    }
    section.forgot-password div.form p{
        
        padding:10px 15px;
        
        font-size:13px;
    }
}
@media (max-width:270px){
    section.forgot-password h1{
        font-size:20px;
      
    }
}



section.reset-password{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/v1606016777/get-started1_yhyuyd.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
section.reset-password p.Error{
    font-size:30px;
    text-align:center;
}


section.reset-password h1{
    font-size:30px;
    margin-bottom: 25px;
    line-height:20px;
    border:1px solid #1e0338;
    padding:15px 20px;
    animation:animate 2s infinite;
}

@keyframes animate{
    0%{
        border-left-color:#9849b0;
        /* color:pink; */
    }

    25%{
        border-top-color:#e16d9b;
    }

    50%{
        border-right-color:#9849b0;
        
    }

    75%{
        border-bottom-color:#e16d9b;
    }

    100%{
        border-color:#1e0338;
    }
}


section.reset-password div.form{
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background:#000000ed;
    
}

section.reset-password div.form .fa{
    color:#5d3b86;
}
section.reset-password div.form h3{
    font-size:15px;
    margin-bottom: 10px;
}

section.reset-password form div{
    display: flex;
    align-items: center;
    border:1px solid white;
    padding:0 10px;
    border-radius:5px;
    margin:5px 0 15px 0;
    position:relative;
    /* flex-direction: column; */
   
}

section.reset-password form div .eye{
    position: absolute;
    right:10px;
}

section.reset-password form div .eye:hover{
    cursor:pointer;
    transition: all 0.3s ease-in-out;
    color:#d764a3;
}

section.reset-password form div .fa-eye-slash {
    display: none;
}

section.reset-password form div input{
    background: transparent;
    outline: none;
    border:none;
    padding: 10px 15px;
    
    color: white;
}

section.reset-password div.form p{
    margin-top:10px;
    padding:10px 15px;
    background:#5d3b86;
    border-radius: 5px;
    font-size:15px;
}

section.reset-password div.form p:hover{
    cursor:pointer;
    transition: all 0.5s ease-in;
    background-color: #6f42a5;
}   
@media (max-width:360px){
    section.reset-password h1{
        font-size:25px;
        
    }
  
section.reset-password div.form p{
   
    padding:10px 10px;
   
    font-size:11px;
}
section.reset-password p.Error{
    font-size:20px;
}
}

@media (max-width:270px){
    section.reset-password h1{
        font-size:20px;
        
    }
}
section.login-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/v1606016777/get-started1_yhyuyd.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: scroll;
}

section.login-page h1{
    margin-bottom: 30px;
    font-size:35px;
}

section.login-page > div{
    background:#000000ed;
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

section.login-page > div h3{
    font-size:13px;
    margin-bottom:10px;
    text-align:center;
    /* background: #b254ab; */
    width:70%;
    margin:8px auto;
    
    
}



section.login-page > div form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:50%;
}

section.login-page > div form div{
    display: flex;
    align-items: center;
    border:1px solid #5d3b86;
    
    border-radius:5px;
    margin:5px 0 15px 0;
    padding: 10px 15px;
    position:relative;
    /* width:50%; */
}

section.login-page > div form div input{
    background: transparent;
    outline: none;
    border:none;
    padding: 5px 10px;
    width:100%;
    color: white;
}

section.login-page .fa{
    color:#5d3b86;
    margin-right:5px;
}

section.login-page .eye{
    position: absolute;
    right:10px;
}

section.login-page .eye:hover{
    cursor:pointer;
    transition: all 0.3s ease-in-out;
    color:#d764a3;
}

section.login-page .fa-eye-slash {
    display: none;
}

section.login-page > div p{
    margin-top:10px;
    padding:10px 15px;
    background:#5d3b86;
    border-radius: 5px;
    font-size:15px;
}

section.login-page > div p:hover{
    cursor:pointer;
    transition: all 0.5s ease-in;
    background-color: #6f42a5;
}   

section.login-page > div h6 a{
    color:#d764a3;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}

section.login-page > div h6.sign-up{
    margin-top:30px;
}




/* MEDIA QUERY */
@media screen and (max-width: 850px){
    section.login-page > div form{
       
        width:70%;
    }
}


@media screen and (max-width: 650px){
    section.login-page > div form{
       
        width:80%;
    }
}




@media screen and (max-width: 360px){
   
}

@media screen and (max-width: 270px){
    
}
section.sign-up-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/v1606016777/get-started1_yhyuyd.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y:scroll;
}

section.sign-up-page h1{
    margin-bottom: 30px;
   text-align:center;
    font-size:35px;
    color:white;
}

section.sign-up-page > div{
    /* background:#3a1b58; */
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background:#000000ed;
}

section.sign-up-page > div h3{
    font-size:15px;
    margin-bottom:10px;
    text-align: center;
}

section.sign-up-page > div form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

section.sign-up-page > div form div{
    display: flex;
    align-items: center;
    border:1px solid #5d3b86;
 
    border-radius:5px;
    padding: 10px 15px;
    margin:5px 0 5px 0;
    position:relative;
    width:80%;
}

section.sign-up-page > div form h6{
    margin-bottom:10px;
    color:grey;
    width: 80%;
}

section.sign-up-page > div form div.password,
section.sign-up-page > div form div.key{
    margin:5px 0 5px 0;
}

section.sign-up-page > div form div input{
    background: transparent;
    outline: none;
    border:none;
    padding: 5px 10px;
    width:100%;
    color: white;
}



section.sign-up-page .fa{
    color:#5d3b86;
    margin-right:5px;
}

section.sign-up-page .eye{
    position: absolute;
    right:10px;
}

section.sign-up-page .eye:hover{
    cursor:pointer;
    transition: all 0.3s ease-in-out;
    color:#d764a3;
}

section.sign-up-page .fa-eye-slash {
    display: none;
}

section.sign-up-page > div p{
    margin-top:10px;
    padding:10px 15px;
    background:#5d3b86;
    border-radius: 5px;
    font-size:15px;
}

section.sign-up-page > div p:hover{
    cursor:pointer;
    transition: all 0.5s ease-in;
    background-color: #6f42a5;
}   

section.sign-up-page > div h6 a{
    color:#d764a3;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}

section.sign-up-page > div h6.sign-in{
    margin-top:30px;
}
@media (max-width:650px){
    section.sign-up-page > div{
        
        /* width:80%; */
       
    } 
}

@media (max-width:480px){
    section.sign-up-page > div{
        /* background:none; */
      
       
    } 
    section.sign-up-page{
        
        height: 140vh;
        
    }
    section.sign-up-page > div form h6{
        
        /* width: 100%; */
    }
    section.sign-up-page > div p{
        
        font-size:13px;
    }
    section.sign-up-page > div h3{
        font-size:13px;
        margin:10px 0;
        
    }
    section.sign-up-page > div{
        /* margin-top:15px; */
    }
    section.sign-up-page > div form div{
      
       
        margin:10px 0 10px 0;
       
    }
}

@media (max-width:360px){
    section.sign-up-page{
      
        height: 120vh;
        
    }
    section.sign-up-page > div form h6{
       
        /* width: 90%; */
    }
    
}

/* LANDSCAPE */
@media (max-height:500px){
    section.sign-up-page{
        height: 100%;
    }
}

*{
    font-family: 'Poppins', sans-serif  ;
}
section.landing-page{
    height: 100vh;
    /* background: white; */
    /* overflow-y:hidden; */

    background:linear-gradient(0deg,#d362a3, #8140b5);
}
section.no-overflow{
    /* overflow-y:hidden; */

}

section.landing-page div.navbar{
    background-color: #ffffff;
    
    
    
}

section.landing-page div.navbar header img.site-logo {
    height: 150px;
    background: transparent;
    
}

section.landing-page div.navbar header{
    display: flex;
    position: fixed;
     left:0;
     right:0;   
     z-index:5;
     padding:0 50px;

    justify-content: space-between;
    height:88px;
    align-items:center;
    /* background-color:transparent; */
    background:linear-gradient(0deg,#d362a3, #8140b5);

    color:#333333;

}

section.landing-page div.navbar header nav{
    display:flex;
    flex-direction:row;
    align-items:center;
    /* justify-content:space-between; */
}
section.landing-page div.navbar header .fa-bars{
    color:white;
    display:none;
}
section.landing-page div.navbar header nav a,
section.landing-page .mobile-navigation a{
    text-decoration:none;
    margin:0 15px;
    /* color:#333333; */
    color:white;
    font-weight:bold;
    font-family: 'Poppins', sans-serif  ;
    /* font-family: 'Open Sans', 'Roboto', 'Helvetica', Sans-Serif; */
    cursor:pointer;

}

section.landing-page div.navbar header nav a:hover,
    section.landing-page .mobile-navigation a:hover{
    transition:all 0.5s ease-in-out;
    /* color:grey; */
    
}

/* section.landing-page div.navbar header nav a:nth-of-type(5),
    section.landing-page .mobile-navigation a:nth-of-type(5){
    padding:10px 25px;
    color:white;
    border-radius:20px;
    background:linear-gradient(0deg, #8140b5, #d362a3);
    cursor:pointer;
} */

/* section.landing-page div.navbar header nav a:nth-of-type(5):hover,
    section.landing-page .mobile-navigation a:nth-of-type(5):hover{
    transform:scale(0.95)
} */
section.landing-page .mobile-navigation{
    position:fixed;
    color:blue;
    background:#8140b5f0;
    z-index:5;
    top:0;
    bottom:0;
    left:0;
    right:0; 
   height:100%;  
   display:none;  
}
section.landing-page .mobile-navigation a:nth-of-type(1){
    animation: flow-in .3s ease forwards;
     opacity:0;
}


section.landing-page .mobile-navigation a:nth-of-type(2){
    animation: flow-in .3s ease forwards .3s;
    opacity:0;
}

section.landing-page .mobile-navigation a:nth-of-type(3){
    animation: flow-in .3s ease forwards .6s;
    opacity:0;
}

section.landing-page .mobile-navigation a:nth-of-type(4){
    animation: flow-in .3s ease forwards .9s;
    opacity:0;
}

section.landing-page .mobile-navigation a:nth-of-type(5){
    animation: flow-in .3s ease forwards 1.12s;
    opacity:0;
}
@keyframes flow-in{
    from{
        opacity:0;
        transform: translate(0%,-500%);
    }
    to{
        opacity:1;
        transform: translate(0%,0%);
    }
}

section.landing-page .get-started{
        position: relative;
        top:80px;
        padding:10px 0;
        /* background-image:url('/images/get-started.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed; */
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        height:100%;
        background:linear-gradient(0deg, #8140b5, #d362a3);
    }
    
section.landing-page .get-started>div{
    position: absolute;
    background:#000000bf;
    width:100%;
    height:100%;
    /* word-break: break-all; */
}
section.landing-page .get-started img{
    height: 100%;
    width:100%;
}
section.landing-page .get-started div div{
    width:60%;
    height:100%;
    
    color:white;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    margin :0 auto;
    text-align:center;
}

section.landing-page .get-started div h1{
    font-size:30px;
    margin-bottom:10px;
    color:#d362a3;
}

section.landing-page .get-started div h3{
    text-align:center;
    /* margin:auto; */
    font-size: 18px;
line-height: 1.5em;
font-family: 'Montserrat', sans-serif;
}


section.landing-page .get-started div a{
    margin-top:50px;
    padding:10px 25px;
    color:white;
    border-radius:20px;
    background:linear-gradient(0deg, #8140b5, #d362a3);
    text-decoration:none;
    transition:all 0.5s ease-in-out;


    
}

section.landing-page .get-started div a:hover{
    
    transform: scale(0.92);
}


section.landing-page .get-started div a{
    
}


@keyframes animate-content{
    from{
        opacity: 0;
        transform:translateY(100px);
    }
    to{
        opacity: 1;
        transform:translateY(0);
    }
}
@keyframes animate-about-us-img{
    from{
        opacity: 0;
        transform:translateX(-100px);
    }
    to{
        opacity: 1;
        transform:translateX(0);
    }
}

section.landing-page .about-us{
    background:linear-gradient(0deg, #8140b5, #d362a3);
    padding:20px 30px;
    position:relative;
    top:60px;
    height:100%;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    align-items:center;
    justify-content:center;
    border-top:1px solid white;
    
    /* margin-top:60px; */
}

section.landing-page .about-us div{
    display:flex;
    flex-direction:column;
    justify-content:center;
    
}

section.landing-page .about-us div:nth-of-type(1){
    height:100%;
    padding:0px 50px;
}

section.landing-page .about-us div:nth-of-type(1) h1{
    margin-bottom: 20px;
    color:#d3bfb9;
}



section.landing-page .about-us div:nth-of-type(1) p{
    margin-bottom: 20px;
    font-size: 18px;
    color:white;
    line-height: 1.5em;
font-family: 'Montserrat', sans-serif;
}

section.landing-page .about-us div:nth-of-type(1) a{
    /* margin-top:50px; */
    padding:10px 25px;
    color:white;
    display:block;
    border-radius:20px;
    background:linear-gradient(0deg, #8140b5, #d362a3);
    text-decoration:none;
    transition:all 0.5s ease-in-out;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}

section.landing-page .about-us div:nth-of-type(1) a:hover{
    transform: scale(0.92);
}

section.landing-page .about-us div:nth-of-type(2){
 align-items:center;
}


section.landing-page .about-us div:nth-of-type(2) img{
    height: 599px;
    width:400px;
    /* border:1px solid blue; */
    border-radius:10px;

}

section.landing-page .about-us div .color-2{
    text-shadow: 1px -1px 0px #8140b5, 1px 1px 0px white;
    
    /* animation:color-animation 1s infinite; */
}

@keyframes color-animation{
    0%{
    text-shadow: 1px 0px 0px #d865a3, 1px 1px 0px #8400ff;
       
    }


    50%{
    text-shadow: 1px -1px 0px #8140b5, 1px 1px 0px white;

    }

    100%{
    text-shadow: 1px -1px 0px #d865a3, 1px 1px 0px #8400ff;
    ;
    
    }
  }




  section.landing-page .why-us{
    background:linear-gradient(0deg,#d362a3, #8140b5 );
    padding:20px 30px;
    position:relative;
    top:60px;
    height:100%;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    align-items:center;
    justify-content:center;
    border-top:1px solid white;
  }

  section.landing-page .why-us div{
    display:flex;
    flex-direction:column;
    /* justify-content:center;
    align-items:center; */
}

section.landing-page .why-us div.left{
    align-items:center;
    width:100%;
   }
   
   
   section.landing-page .why-us div.left img{
       height: 600px;
       width:400px;
       /* border:1px solid blue; */
       border-radius:10px;
   
   }

   section.landing-page .why-us div.right h1{
       margin-bottom:20px;
   }

   section.landing-page .why-us div.right div div{
       display:flex;
       flex-direction:row;
       margin:10px 0;
       font-size:20px;
   }

   section.landing-page .why-us div.right div div .fa{
    margin-right:10px;
    color:#8140b5;
   }

   section.landing-page .services{
    background:linear-gradient(0deg,#8140b5,#d362a3 );
    padding:20px 70px;
    position:relative;
    top:60px;
    height:100%;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    align-items:center;
    justify-content:center;
    border-top:1px solid white;
  }

  section.landing-page .services div{
    display:flex;
    flex-direction:column;
    /* justify-content:center;
    align-items:center; */
}

section.landing-page .services div.right{
    align-items:center;
    width:100%;
   }


   section.landing-page .services div.right img{
    height: 500px;
    /* width:400px; */
    /* border:1px solid blue; */
    border-radius:10px;

}

section.landing-page .services div.left h1{
    margin-bottom:20px;
    font-size: 30px;
}

section.landing-page .services div.left div div{
    display:flex;
    flex-direction:row;
    margin:10px 0;
    font-size:18px;
}

section.landing-page .services div.left div div .fa{
    margin-right:10px;
    color:#8140b5;
   }



section.landing-page .pricing{
    background:linear-gradient(0deg,#d362a3,#8140b5 );
    padding:20px 30px;
    position:relative;
    top:60px;
    height:80%;
    display:flex;
    flex-direction:column;
    align-items:center;
    border-top:1px solid white;
    
    
    
}


section.landing-page .pricing h1{
    padding:20px 0;
}


section.landing-page .pricing > div{
    background:#ffffff;
    color:black;
    width:70%;
    height:70%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    border-radius:15px;
    /* overflow-y:scroll; */
    /* border-top-left-radius:50px; */
    
    /* height:100%; */
}

section.landing-page .pricing > div a{
    padding:10px 25px;
    color:white;
    display:block;
    border-radius:20px;
    background:linear-gradient(0deg, #8140b5, #d362a3);
    text-decoration:none;
    transition:all 0.5s ease-in-out;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    margin-top:20px;
}

section.landing-page .pricing > div a:hover{
    transform: scale(0.92);
}

section.landing-page .pricing div  div.table-head,
section.landing-page .pricing div  div.table-data
{
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    width:100%;
    justify-content:center;
    align-items: center;
    /* height:100%; */
}

section.landing-page .pricing div  div.table-head{
    border-bottom:1px solid #cccccc;
    color:#ce85c0;
    font-weight:bolder;
    padding-bottom:20px;
}
section.landing-page .pricing div  div.table-data div{
    margin-top:10px;
}
section.landing-page .pricing div  div.table-data div ul{
margin: 10px 0;
/* color:pink */
}

section.landing-page .pricing div  div.table-head div,
section.landing-page .pricing div  div.table-data div{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
}

section.landing-page .pricing  ul li{
    list-style:none;
}

section.landing-page .partners{
    background:linear-gradient(0deg,#8140b5,#d362a3 );
    /* padding:20px 30px; */
    position:relative;
    /* top:30px; */
    height:20%;
    border-top:1px solid white;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  section.landing-page .partners img{
    /* cursor: pointer; */
    /* filter: grayscale(5); */
    opacity: 0.6;
    display: inline-block;
    vertical-align: middle;
    height: 35px;
    width: auto;
    margin: 0.7em 1.8em;
    transition: all ease-in-out 0.5s;
  }






/* HEADER */
@media (max-width: 850px){
   
    section.landing-page div.navbar header nav a{
        
        margin:0 10px;
      
    
    }
  }
  
  @media (max-width: 767px){
   
    section.landing-page div.navbar header nav a{
        
        margin:0 10px;
        font-size:15px;
      
    
    }
    section.landing-page div.navbar header{
  
         padding:0 20px;
    
     
        /* height:60px; */
      
    }
    section.landing-page div.navbar header img.site-logo {
        height: 100px;
        
        
    }

}
    @media (max-width: 650px){
   
        section.landing-page div.navbar header nav.mobile-hide{
            
           display:none;
          
        
        }
        section.landing-page div.navbar header img.site-logo {
            height: 100px;
            
            
        }
        section.landing-page div.navbar header{
  
            padding:0 20px;
       
        
           height:60px;
         
       }
       section.landing-page div.navbar header .fa-bars{
        /* color:white; */
        display:block;
        font-size:20px;
    }

    section.landing-page .mobile-navigation{
        display:none;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        /* visibility: hidden; */
    overflow-y:hidden;

        animation:hide-mobile 3s ease forwards;
    }
    @keyframes hide-mobile{
        from{
            display:flex;
            transform: scale(1);
            /* transform: translate(0%,-500%); */
        }
        to{
            transform: scale(0);
            display: none;
            /* transform: translate(0%,0%); */
        }
    }
    section.landing-page .mobile-navigation-show{
        display:flex;
        animation:show-mobile .6s ease forwards;
    }
    @keyframes show-mobile{
        from{
            display:none;
            transform: scale(0.5);

            /* transform: translate(0%,-500%); */
        }
        to{
            display:flex;
            transform: scale(1);
            /* transform: translate(0%,0%); */
        }
    }
    section.landing-page .mobile-navigation a{
        margin:25px 0;
    }
  }

  @media (max-width: 360px){
    section.landing-page div.navbar header img.site-logo {
        height: 80px;
        
        
    }
  }

  @media (max-width: 270px){
    section.landing-page div.navbar header img.site-logo {
        height: 50px;
        
        
    }
  }
  /* GETTING STARTED */
  @media (max-width: 767px){
   
    section.landing-page .get-started div div{
      
        width:70%;
       
    }
    
  

}


@media(max-width: 650px){
    section.landing-page .get-started{
       
        top:50px;
        
    }


}
@media(max-width: 480px){
    section.landing-page .get-started div div{
      
        width:80%;
       
    }
    section.landing-page .get-started div h1{
        font-size:25px;
        
    }
    section.landing-page .get-started div h3{
        
        font-size: 15px;
    line-height: 1.5em;
   
    }

    section.landing-page .get-started div a{
       
        font-size:14px;
    }
    /* section.landing-page .get-started{
        position: relative;
        top:60px;
        
    } */
}



/* ABOUT US */
@media (max-width: 850px){
    section.landing-page .about-us div:nth-of-type(2) img{
        height: 499px;
        width:300px;
        
    
    }
    section.landing-page .about-us div:nth-of-type(1){
       
        padding:0px 20px;
    }
  }

  @media (max-width: 650px){
    section.landing-page .about-us{
        text-align: center;
        top:40px;
       
        padding:10px 20px;
        /* margin-top:60px; */
    }
    section.landing-page .about-us div:nth-of-type(1){
        height:100%;
        width:100%;
        padding:0px 5px;
        /* margin-right:5px; */
    }
    section.landing-page .about-us div{
        
        justify-content:center;
        align-items:center;
    }
  }

  @media (max-width: 520px){
    section.landing-page .about-us div:nth-of-type(2){
        display:none;
       }
       section.landing-page .about-us{
        grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (max-width: 480px){
    section.landing-page .about-us div:nth-of-type(2){
        display:none;
       }
       section.landing-page .about-us{
        grid-template-columns: repeat(1, 1fr);
    }
  }

  /* WHY US */
  @media (max-width: 850px){
   
    section.landing-page .why-us div.left img{
        height: 400px;
        width:300px;
        
    
    }
  }
  @media (max-width: 650px){
    section.landing-page .why-us{
        padding:10px 10px;
        top:40px;
        
      }
    
  }

  @media (max-width: 520px){
    section.landing-page .why-us{
        
        grid-template-columns: repeat(1, 1fr);
        
      }
      section.landing-page .why-us div.left{
        display:none;
       }
       section.landing-page .why-us div.right{
       text-align: center;
       display: flex;
       justify-content: center;
       align-items: center;
       /* width:80%; */
    }

}
  @media (max-width: 480px){
    section.landing-page .why-us{
        
        grid-template-columns: repeat(1, 1fr);
        
      }
      section.landing-page .why-us div.left{
        display:none;
       }
       section.landing-page .why-us div.right{
       text-align: center;
       display: flex;
       justify-content: center;
       align-items: center;
       /* width:80%; */
    }

}
    @media (max-width: 270px){
       
        section.landing-page .why-us div.right div div{
            
            font-size:15px;
        }
    
  }

  /* Services */
  @media (max-width: 850px){
   
    section.landing-page .services div.right img{
        height: 400px;
        width:300px;
        
    
    }
  }
  @media (max-width: 650px){
    section.landing-page .services{
        padding:10px 10px;
        top:40px;
        
      }
    
  }

  @media (max-width: 520px){
    section.landing-page .services{
        
        grid-template-columns: repeat(1, 1fr);
        
      }
      section.landing-page .services div.right{
        display:none;
       }
       section.landing-page .services div.left{
       text-align: center;
       display: flex;
       justify-content: center;
       align-items: center;
       /* width:80%; */
    }

}
  @media (max-width: 480px){
    section.landing-page .services{
        
        grid-template-columns: repeat(1, 1fr);
        
      }
      section.landing-page .services div.right{
        display:none;
       }
       section.landing-page .services div.left{
       text-align: center;
       display: flex;
       justify-content: center;
       align-items: center;
       /* width:80%; */
    }
    
section.landing-page .services div.left div div{
  
    font-size:16px;
}

}
    @media (max-width: 270px){
       
        section.landing-page .services div.left div div{
            
            font-size:15px;
        }
    
  }

  /* PRICING */
  
  @media (max-width: 1050px){
   
    section.landing-page .pricing > div{
        
        width:90%;
        /* height:80%; */
        
    }
    section.landing-page .pricing div  div.table-data
{
    font-size:15px;
    /* height:100%; */
    text-align:center;
}

section.landing-page .pricing{
  
    top:50px;

    
    
}
  }

  @media (max-width: 650px){
    section.landing-page .pricing{
  
        top:40px;
    }
    section.landing-page .pricing > div{
        
        width:100%;
        height:80%;
        
    }
    section.landing-page .pricing div  div.table-data
    {
        font-size:10px;
        /* height:100%; */
        text-align:center;
    }

    section.landing-page .pricing > div a{
    padding:5px 20px;
   font-size:15px;
}
  }

  @media (max-width: 360px){
    section.landing-page .pricing{
  
        padding:20px 10px;
    }
  }


    /* Partners */
  
    @media (max-width: 1050px){
   
        /* section.landing-page .partners{
            padding:20px 30px;
            
            top:30px;
            height:30%;
            border-top:1px solid white;
            overflow: hidden;
            display: flex;
          } */
      }





      /* LANDSCAPE */
  @media(max-height:500px){

     /* HEADER-LANDSCAPE */
    section.landing-page div.navbar header img.site-logo {
        height: 100px;
     }
    section.landing-page div.navbar header{
        height:60px;
    }

    section.landing-page div.navbar header nav a{
    font-size: 13px;
}
section.landing-page .mobile-navigation a:nth-of-type(4),
section.landing-page div.navbar header nav a:nth-of-type(4){
    display:none;
}
section.landing-page .mobile-navigation a{
    font-size: 15px;
    margin:18px 0;
}

/* GETTING-STARTED-LANDSCAPE */
section.landing-page .get-started{
    top:50px;
}
section.landing-page .get-started div div{
    width:70%;
}
section.landing-page .get-started div h1{
    font-size:40px;
}
section.landing-page .get-started div h3{
    display:none;
}


/* ABOUT-US-LANDSCAPE */
section.landing-page .about-us{
    top:40px;
    padding:20px 30px;
}
section.landing-page .about-us div:nth-of-type(1){
    padding: 0px 10px
}
section.landing-page .about-us div:nth-of-type(1) h1{
    /* font-size:20px; */
}
section.landing-page .about-us div:nth-of-type(1) p{
    font-size:15px;
}
section.landing-page .about-us div:nth-of-type(2) img{
    height: 299px;
    width: 200px;
}

/* WHY-US-LANDSCAPE */
section.landing-page .why-us{
    top:40px;
}
 
section.landing-page .why-us div.left img{
    
    height: 299px;
    width: 200px;
}
section.landing-page .why-us div.right div div{
    margin:7px 0;
    font-size:15px;
}

/* SERVICES-LANDSCAPE */
section.landing-page .services{
    top:40px;
    margin-bottom: 40px;
}
section.landing-page .services div.right img{
    height: 299px;
    width: 200px;

}
section.landing-page .services div.left div div{
    
    margin:7px 0;
    font-size:15px;
}

/* PRICING-LANDSCAPE */
section.landing-page .pricing{
    padding:10px 30px;
    top:40px;
    height:100%;
    display:none;
}
  }

 .contact-us{
    background:#000000;
    /* background:linear-gradient(0deg, #8140b5,#d362a3); */

    padding:10px 0;
    position:relative;
    /* top:60px; */
    bottom:0;
    height:20%;
    display:grid;
    grid-template-columns:repeat(3,1fr);
    /* flex-direction:column; */
    align-items:center;
    justify-content:center;
    text-align: center;
    border-top:1px solid white;
}

 .contact-us .contact h3
{
    font-weight:100;
    margin-bottom:10px;
}
 .contact-us img.site-logo{
    height:100px;
    background:transparent;
}
 .contact-us .contact{
    display:flex;
    flex-direction:column;
    align-items:center;
    
}

 .contact-us .contact p{
    color: #414141;
    margin:5px 0;
}
 .contact-us .social-links p{
    margin-bottom:10px;

}
 .contact-us .social-links a {
    color: #414141;
    margin:0 5px;
    flex-direction:row;
    transition: all 0.3s ease-in;
    text-decoration: none;
}

 .contact-us .social-links a:hover{
    color:white;
}

/* FOOTER */
@media (max-width:1050px){
    .contact-us{
    
       height: 30%;
   
   }
}
@media (max-width:850px){
     .contact-us{
     
        /* top:40px; */
    
    }
}
  @media (max-width: 767px){
   
     .contact-us{
       
        height:21%;
       
    }
     .contact-us{
     
        /* top:40px; */
    
    }

}

@media (max-width: 650px){
     .contact-us .contact p{
        font-size:13px;
        margin:5px 0;
    }
}
@media (max-width: 520px){
     .contact-us{
        height:50%;
        grid-template-columns:repeat(1,1fr);
       
    }
}
@media (max-width: 480px){
     .contact-us{
        height:50%;
        grid-template-columns:repeat(1,1fr);
       
    }
}


/* LANDSACAPE */
@media (max-height:500px){
    .contact-us{
        height:100%;
    }
}
* {
    box-sizing: border-box;
  }
  
  
  
  #notfound {
    position: relative;
    height: 100vh;
    background: #030005;
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .notfound {
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
  }
  
  .notfound .notfound-404 {
    position: relative;
    height: 180px;
    margin-bottom: 20px;
    z-index: -1;
  }
  
  .notfound .notfound-404 h1 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
    font-size: 224px;
    font-weight: 900;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -12px;
    color: #030005;
    text-transform: uppercase;
    text-shadow: 1px -1px 0px #8400ff, 1px 1px 0px #d865a3;
    letter-spacing: -20px;

    animation:color-animate 1s infinite;
  }

  @keyframes color-animate{
    0%{
    text-shadow: -1px 1px 0px #d865a3, 1px 1px 0px #8400ff;

    }


    50%{
    text-shadow: 1px -1px 0px #8400ff, 1px 1px 0px #d865a3;

    }

    100%{
    text-shadow: -1px 1px 0px #d865a3, 1px 1px 0px #8400ff;

    }
  }
  
  
  .notfound .notfound-404 h2 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 0;
    right: 0;
    top: 110px;
    font-size: 42px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0px 2px 0px #8400ff;
    letter-spacing: 13px;
    margin: 0;
  }
  
  .notfound a {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    text-transform: uppercase;
    color: #9146b1;
    text-decoration: none;
    border: 2px solid;
    background: transparent;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 700;
    transition: 0.2s all;
  }
  
  .notfound a:hover {
    color: #8400ff;
  }
  
  @media only screen and (max-width: 767px) {
      .notfound .notfound-404 h2 {
          font-size: 24px;
      }
  }
  
  @media only screen and (max-width: 480px) {
    .notfound .notfound-404 h1 {
        font-size: 182px;
    }
  }
  
@media (max-width:360px){
    .notfound .notfound-404 h1 {
        
        font-size: 150px;
       
      }
      .notfound .notfound-404 h2 {
       
        font-size: 22px;
       
      }
}
@media (max-width:270px){
    .notfound .notfound-404 h1 {
        
        font-size: 100px;
       
      }
      .notfound .notfound-404 h2 {
       
        font-size: 18px;
       
      }
      .notfound a {
      
        padding: 7px 35px;
        font-size: 13px;
        
      }
}
section.verify-email{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/v1606016777/get-started1_yhyuyd.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y:scroll;
}

section.verify-email > div{
    /* background:#3a1b58; */
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background:#000000ed;
}

section.verify-email > div h3{
    text-align: center;
}


section.faqs div.image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/c_scale,h_669/v1607619150/faqs_wq7iuo.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* overflow-y: scroll; */
}

section.faqs div.image-overlay{
    background-color: #1e0338a1;
    position: absolute;
    z-index: 1;
    height: 250px;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 100px;
}
/* section.faqs div.image-overlay h1{
    display:flex;
} */


section.faqs div.write-up{
    background: #ffebcdbf;
    /* height: 100vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 100px 80px;
}

section.faqs div.write-up h1{
    font-size: 30px;
    margin-bottom:20px;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Poppins', sans-serif  ;
}
section.faqs div.write-up div{
    margin-bottom:30px;
    border-bottom:1px solid white;
    padding:30px 0;
}
section.faqs div.write-up p{
 margin-top:10px;
 color: #1b092c;
 font-family: 'Poppins', sans-serif  ;
 /* font-family: 'Raleway', sans-serif; */
}
section.faqs div.write-up p i{
    margin-right:15px;
}

@keyframes animate-from-right{
    from{
        opacity: 0;
        transform:translateX(100px);
    }
    to{
        opacity: 1;
        transform:translateX(0);
    }
}

@keyframes animate-from-left{
    from{
        opacity: 0;
        transform:translateX(-100px);
    }
    to{
        opacity: 1;
        transform:translateX(0);
    }
}




@media (max-width:850px){
    section.faqs div.write-up h1{
        font-size: 25px;
        
    }

    section.faqs .contact-us{
        top: 0;
    }
}

@media (max-width:650px){
    section.faqs div.write-up h1{
        font-size: 20px;
        
    }
    section.faqs div.write-up p{
        font-size: 15px;
       }
}

@media (max-width:520px){
    section.faqs div.write-up{
        
        padding: 100px 50px;
    }
    section.faqs div.image-overlay{
        
        font-size: 70px;
    }
}

@media (max-width:360px){
    section.faqs div.write-up h1{
        font-size: 16px;
        
    }
    section.faqs div.write-up p{
        font-size: 13px;
       }
    section.faqs div.write-up{
        
        padding: 100px 20px;
    }
}

@media (max-width:270px){
    section.faqs div.image-overlay{
        
        font-size: 60px;
    }
}

/* LANDSCAPE  */
@media (max-height:500px){
    section.faqs div.image{
        
        height: 100vh;
        
    }

    
section.faqs div.image-overlay{
  
    height: 100vh;
    
}
}
section.login-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/v1606016777/get-started1_yhyuyd.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: scroll;
}

section.login-page h1{
    margin-bottom: 30px;
    font-size:35px;
}

section.login-page > div{
    background:#000000ed;
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

section.login-page > div h3{
    font-size:13px;
    margin-bottom:10px;
    text-align:center;
    /* background: #b254ab; */
    width:70%;
    margin:8px auto;
    
    
}



section.login-page > div form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:50%;
}

section.login-page > div form div{
    display: flex;
    align-items: center;
    border:1px solid #5d3b86;
    
    border-radius:5px;
    margin:5px 0 15px 0;
    padding: 10px 15px;
    position:relative;
    /* width:50%; */
}

section.login-page > div form div input{
    background: transparent;
    outline: none;
    border:none;
    padding: 5px 10px;
    width:100%;
    color: white;
}

section.login-page .fa{
    color:#5d3b86;
    margin-right:5px;
}

section.login-page .eye{
    position: absolute;
    right:10px;
}

section.login-page .eye:hover{
    cursor:pointer;
    transition: all 0.3s ease-in-out;
    color:#d764a3;
}

section.login-page .fa-eye-slash {
    display: none;
}

section.login-page > div p{
    margin-top:10px;
    padding:10px 15px;
    background:#5d3b86;
    border-radius: 5px;
    font-size:15px;
}

section.login-page > div p:hover{
    cursor:pointer;
    transition: all 0.5s ease-in;
    background-color: #6f42a5;
}   

section.login-page > div h6 a{
    color:#d764a3;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}

section.login-page > div h4.pin-info{
    margin-top:30px;
    text-align: center;
}





/* MEDIA QUERY */
@media screen and (max-width: 850px){
    section.login-page > div form{
       
        width:70%;
    }

    section.login-page h1 {
        margin-bottom: 30px;
        font-size: 18px;
    }

    section.login-page > div h4.pin-info{
        margin-top:20px;
        font-size: 14px;
    }

    
section.login-page > div h3 {
    font-size: 9px;
    text-align: center;
    
}
}


@media screen and (max-width: 650px){
    section.login-page > div form{
       
        width:80%;
    }
}




@media screen and (max-width: 360px){
    section.login-page > div form{
       
        width:80%;
    }
}

@media screen and (max-width: 270px){
    
}
section.privacy div.image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/c_scale,h_669/v1607619150/faqs_wq7iuo.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* overflow-y: scroll; */
}

section.privacy div.image-overlay{
    background-color: #1e0338a1;
    position: absolute;
    z-index: 1;
    height: 250px;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 100px;
}

section.privacy div.privacy-content{
    background: #ffebcdbf;
    /* height: 100vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 100px 80px;
}

section.privacy div.privacy-content p{
    margin-top:20px;
    color: #1b092c;
    font-family: 'Poppins', sans-serif  ;
    font-size: 15px;
}

section.privacy div.privacy-content p.intro{
    font-size: 20px;
    margin-bottom:20px;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Poppins', sans-serif  ;
}

section.privacy div.privacy-content ul{
    margin-top:20px;
    color: #1b092c;
    font-family: 'Poppins', sans-serif  ;
    font-size: 15px;
    /* list-style: none; */
}

section.privacy div.privacy-content p.question{
    font-size: 20px;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Poppins', sans-serif  ;
}
/* LANDSCAPE  */
@media (max-height:500px){
    section.privacy div.image{
        
        height: 100vh;
        
    }

    
section.privacy div.image-overlay{
  
    height: 100vh;
    
}
}

@media (max-width:270px){
    section.privacy div.image-overlay{
        
        font-size: 60px;
    }
}

@media (max-width:520px){
    section.privacy div.privacy-content{
        
        padding: 100px 50px;
    }
    section.privacy div.image-overlay{
        
        font-size: 70px;
    }

    section.privacy div.image-overlay h1{
        
        font-size: 40px;
    }
}

@media (max-width:768px){
    

    section.privacy div.image-overlay h1{
        
        font-size: 70px;
    }
}
@media (max-width:426px){
    

    section.privacy div.image-overlay h1{
        
        font-size: 40px!important;
    }

    section.privacy div.privacy-content p.intro{
    font-size: 15px;
}

section.privacy div.privacy-content p.question{
    font-size: 14px;
}
section.privacy div.privacy-content p{
    font-size: 11px;
}
}
