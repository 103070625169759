section.cable-tv-admin h3{
    display:flex;
    font-size:30px;
    text-align: center;
    /* margin-bottom:20px; */
    width:70%;
    margin:20px auto;
}

section.cable-tv-admin > div{
    display:flex;
    flex-direction:column;
    border:3px solid white;
    height:100%;
    width:80%;
    margin:auto;
    padding:2%;
    align-items:center;
    border-radius:10px;
}

section.cable-tv-admin > div form{
    display:flex;
    flex-direction: column;
}



section.cable-tv-admin > div form div{
    margin:5px 0;
}


section.cable-tv-admin > div form >div{
    display: flex;
    flex-direction: column;
}

section.cable-tv-admin > div form label{
    margin-bottom:10px;
}

section.cable-tv-admin > div form >div select option{
    color: white;
    background:#1e0338;
    border:none;
    outline:none;
    padding:3px 0;
}

section.cable-tv-admin > div form input,
section.cable-tv-admin > div form >div select{
    outline:none;
    border:none;
    padding:10px 10px;
    color:white;
    background:transparent;
    border:1px solid #7e3eb5;
}

section.cable-tv-admin > div form div.floating-inputs{
    display: flex;
    flex-direction:row;
}

section.cable-tv-admin > div form div.floating-inputs .inputs input{
    margin:0 5px;
}

section.cable-tv-admin > div form div.floating-inputs .options{
    display:flex;
    align-items: center;
    margin-left:5px;
}

section.cable-tv-admin > div form div.floating-inputs .options p{
    background: #7e3eb5;
    padding:5px 10px;
    border-radius:5px;
    cursor:pointer;
}
