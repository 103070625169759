section.coupons .wrapper{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding:20px 0;
    /* justify-content:center; */
    width:100%;
    grid-gap: 20px;
    margin-top: 10%;
}


section.coupons h2{
    font-size: 30px;
}
@media (max-width:650px){
    section.coupons h2{
        font-size: 25px;
    }
}
@media (max-width:480px){
    
    section.coupons .wrapper{
        display: block;
        
        
    }
    section.coupons .wrapper div{
        margin:10px 0;
    }
}