div.personal-wallet{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:60px 0;
}

div.personal-wallet .left h3{
    font-size:30px;
    color:#ffa92c;
    border:2px dotted #fc5eac;
    padding:10px;

}

div.personal-wallet .left h5{
    padding:15px 0;
    font-size:25px;
    color:#fc5eac;
}

div.personal-wallet .left h5 p{
    color:white;
}

div.personal-wallet .right p{
    background: #300d42;
    padding: 10px 10px;
    border-radius:10px;
    font-size:15px;
}

div.personal-wallet .right p:hover{
    cursor:pointer;
    transform:scale(0.95) ;

}