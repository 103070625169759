div.admin-loading .fa{
    animation: rotate 1.5s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
    font-size:50px;
    /* color:white; */
}

@keyframes rotate{
    0%{
        transform: rotateZ(0deg);
    color:white;

    }

    /* 50%{
        
    color:#b856a9;

    } */

    100%{
        transform: rotateZ(360deg);
        color:#b856a9;
        font-size:60px;

    }
}