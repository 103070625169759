 .contact-us{
    background:#000000;
    /* background:linear-gradient(0deg, #8140b5,#d362a3); */

    padding:10px 0;
    position:relative;
    /* top:60px; */
    bottom:0;
    height:20%;
    display:grid;
    grid-template-columns:repeat(3,1fr);
    /* flex-direction:column; */
    align-items:center;
    justify-content:center;
    text-align: center;
    border-top:1px solid white;
}

 .contact-us .contact h3
{
    font-weight:100;
    margin-bottom:10px;
}
 .contact-us img.site-logo{
    height:100px;
    background:transparent;
}
 .contact-us .contact{
    display:flex;
    flex-direction:column;
    align-items:center;
    
}

 .contact-us .contact p{
    color: #414141;
    margin:5px 0;
}
 .contact-us .social-links p{
    margin-bottom:10px;

}
 .contact-us .social-links a {
    color: #414141;
    margin:0 5px;
    flex-direction:row;
    transition: all 0.3s ease-in;
    text-decoration: none;
}

 .contact-us .social-links a:hover{
    color:white;
}
