section.forgot-password{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/v1606016777/get-started1_yhyuyd.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

section.forgot-password h1{
    font-size:30px;
    margin-bottom: 30px;
    line-height:20px;
    border:1px solid #1e0338;
    padding:15px 20px;
    animation:animate-h1 2s infinite;
}

@keyframes animate-h1{
    0%{
        border-left-color:#9849b0;
        /* color:pink; */
    }

    25%{
        border-top-color:#e16d9b;
    }

    50%{
        border-right-color:#9849b0;
        
    }

    75%{
        border-bottom-color:#e16d9b;
    }

    100%{
        border-color:#1e0338;
    }
}

section.forgot-password div.form{
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background:#000000ed;
    /* background: blue; */
    
}

section.forgot-password div.form .fa{
    color:#5d3b86;
}
section.forgot-password div.form h3{
    font-size:15px;
    margin-bottom: 10px;
    text-align: center;
}
section.forgot-password div.form h3.resetMsg{
    font-size:20px;
    margin-bottom: 10px;
    text-align: center;
    width:50%;
}

section.forgot-password div.form p{
    margin-top:10px;
    padding:10px 15px;
    background:#5d3b86;
    border-radius: 5px;
    font-size:15px;
}

section.forgot-password div.form p:hover{
    cursor:pointer;
    transition: all 0.5s ease-in;
    background-color: #6f42a5;
}
section.forgot-password form div{
    display: flex;
    align-items: center;
    border:1px solid white;
    padding:0 10px;
    border-radius:5px;
    margin:5px 0 15px 0;
    /* flex-direction: column; */
   
}

section.forgot-password form div input{
    background: transparent;
    outline: none;
    border:none;
    padding: 10px 15px;
    
    color: white;
}

@media (max-width:650px){
    section.forgot-password div.form h3.resetMsg{
        
        width:60%;
    }
}
@media (max-width:520px){
    section.forgot-password div.form h3.resetMsg{
        font-size:20px;
        width:80%;
    }
}
@media (max-width:360px){
    section.forgot-password h1{
        font-size:25px;
      
    }
    section.forgot-password div.form h3{
        font-size:13px;
        
    }
    section.forgot-password div.form h3.resetMsg{
        font-size:16px;
        width:80%;
    }
    section.forgot-password div.form p{
        
        padding:10px 15px;
        
        font-size:13px;
    }
}
@media (max-width:270px){
    section.forgot-password h1{
        font-size:20px;
      
    }
}


