/* MEDIA QUERY */
@media screen and (max-width: 850px){
    section.login-page > div form{
       
        width:70%;
    }

    section.login-page h1 {
        margin-bottom: 30px;
        font-size: 18px;
    }

    section.login-page > div h4.pin-info{
        margin-top:20px;
        font-size: 14px;
    }

    
section.login-page > div h3 {
    font-size: 9px;
    text-align: center;
    
}
}


@media screen and (max-width: 650px){
    section.login-page > div form{
       
        width:80%;
    }
}




@media screen and (max-width: 360px){
    section.login-page > div form{
       
        width:80%;
    }
}

@media screen and (max-width: 270px){
    
}