div.last5transactions{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
   display:flex;
   flex-direction:column;
   padding:2% 5%;
    margin:10px auto;
    
    /* grid-column:1/4; */
}

div.last5transactions h3{
    font-size:20px;
    font-weight:bold;
    margin-bottom:15px;
}

div.last5transactions table{
    /* text-align:center; */
}

div.last5transactions .table-label ul{
    display:flex;
    justify-content: space-between;
    margin-bottom:10px;
    padding:0 30px;
    /* align-items: center; */

}
div.last5transactions .table-label ul li{
    list-style:none
}

div.last5transactions .table-data ul{
    display:flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    padding:15px 20px;
    border-radius:15px;
    margin: 10px 0;
}
div.last5transactions .table-data ul li{
    list-style:none
}

.status{
    text-align:center;
    padding:5px 5px;
    border-radius:10px;
    width: 100px;
}