div.total-number-of-transactions-admin{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:50px 25px;
}

div.total-number-of-transactions-admin div.value{
    /* flex:2; */
    /* border:10px solid #f9a9c6; */
    /* border-radius: 50%; */
    /* width:30px; */
    /* padding:25px 25px; */
    font-size:25px;
    margin-right:10px;
    color:#d2558f;
}

div.total-number-of-transactions-admin >p{
    font-weight:bold;
}

@media (max-width:650px){
    div.total-number-of-transactions-admin >p{
        font-size:15px;
    }
}