@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap');

section.admin-transactions-page .table{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
   display:flex;
   flex-direction:column;
   padding:2% 5%;
    margin:20px auto;
    font-family: 'Roboto Mono', monospace;
    /* grid-column:1/4; */
}
section.admin-transactions-page .header{
    display:flex;
    justify-content: space-between;
    align-items:baseline;

}
section.admin-transactions-page h3{
    font-size:30px;
    font-weight:bold;
    margin-bottom:25px;
}
section.admin-transactions-page .header div{
    display:flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: space-between;
}
section.admin-transactions-page input{
    outline:none;
    border:1px solid white;
    background:transparent;
    /* height:30px; */
    margin-left:10px;
    border-radius:5px;
    padding:10px 15px;
    color:white;
}
section.admin-transactions-page .table div.pagination-box{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top:10px;
}

section.admin-transactions-page .table div.pagination-box div{
    margin:0 5px;
    display:flex;
    flex-direction: row;
}
section.admin-transactions-page .table div.pagination-box div:nth-of-type(2) p{
    margin-right:5px;
    
}
section.admin-transactions-page .table div.pagination-box .fa{
    font-size:30px;
}

section.admin-transactions-page .table div.pagination-box .fa:hover{
    cursor:pointer;
    color:#b454aa;
}
section.admin-transactions-page .table-label ul{
    display:flex;
    justify-content: space-between;
    margin-bottom:10px;
    padding:0 30px;
    /* align-items: center; */

}
section.admin-transactions-page .table-label ul li{
    list-style:none
}

section.admin-transactions-page .table-data ul{
    display:flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    padding:15px 20px;
    border-radius:15px;
    margin: 10px 0;
}

section.admin-transactions-page .table-data ul:hover{
    cursor:pointer;
    transition:all 0.3s ease-in-out;
    border-color:#b454aa;
    background:#b454aa;
    transform:scale(1.05)
}
section.admin-transactions-page .table-data ul li{
    list-style:none
}

section.admin-transactions-page .status{
    text-align:center;
    padding:5px 5px!important;
    border-radius:10px;
    width: 100px!important;
}