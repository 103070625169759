div.admin-tdetails{
    border:1px solid white;
    height:80%;
    width:50%;
    /* padding:0 10px; */
    display:flex;
    flex-direction:column;
    justify-content:normal;
    align-items:center;
    overflow-y: scroll;
    text-align:center;
}

div.admin-tdetails .right{
    padding:0 10px;
}
div.admin-tdetails .right p{
    padding:5px 5px;
    background: #8240b4;
    font-size:16px;
    margin:10px 0;
    /* text-align:center; */
}

div.admin-tdetails .left{
    display: flex;
    
}

div.admin-tdetails .left p{
    padding:10px 10px;
    background: #8240b4;
    font-size:16px;
    margin:10px 10px;
    text-align:center;
    border-radius:5px;
    cursor:pointer;
}

div.admin-tdetails .left p:nth-of-type(2){
    background: #f85d5d;

}