@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
section.privacy div.image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/c_scale,h_669/v1607619150/faqs_wq7iuo.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* overflow-y: scroll; */
}

section.privacy div.image-overlay{
    background-color: #1e0338a1;
    position: absolute;
    z-index: 1;
    height: 250px;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 100px;
}

section.privacy div.privacy-content{
    background: #ffebcdbf;
    /* height: 100vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 100px 80px;
}

section.privacy div.privacy-content p{
    margin-top:20px;
    color: #1b092c;
    font-family: 'Poppins', sans-serif  ;
    font-size: 15px;
}

section.privacy div.privacy-content p.intro{
    font-size: 20px;
    margin-bottom:20px;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Poppins', sans-serif  ;
}

section.privacy div.privacy-content ul{
    margin-top:20px;
    color: #1b092c;
    font-family: 'Poppins', sans-serif  ;
    font-size: 15px;
    /* list-style: none; */
}

section.privacy div.privacy-content p.question{
    font-size: 20px;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Poppins', sans-serif  ;
}