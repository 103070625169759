

section.admin-dashboard{
    position: relative;
    /* padding: 0 20px; */
    height:100vh;
    overflow-x:hidden;
    
    width: 100%;
}



/* MAIN-PAGE */

section.admin-main-page{
    position: absolute;
    left:6%;
    top:5%;
   
    padding: 5% 5%;
    height: 100%;
    width: 94%;
    /* background:white; */
}


section.maximize-mainpage{
    transition:all 0.5s ease-in-out;    
left: 12%;

}
@media (max-width:650px){
    section.admin-main-page{
       
       
        padding: 7% 5%;
        
        /* background:white; */
    }
}

@media (max-width:767px){
    section.maximize-mainpage{
        transition:all 0.5s ease-in-out;    
    left: 18%!important;
    
    }
}

@media (max-width:520px){
    section.admin-main-page{
        
        left:0;
        /* padding: 10% 5%; */
        width: 100%;
        /* background:white; */
    }
    section.maximize-mainpage{
         
    left:0!important;
    
    }
}