section.reset-password{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/v1606016777/get-started1_yhyuyd.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
section.reset-password p.Error{
    font-size:30px;
    text-align:center;
}


section.reset-password h1{
    font-size:30px;
    margin-bottom: 25px;
    line-height:20px;
    border:1px solid #1e0338;
    padding:15px 20px;
    animation:animate 2s infinite;
}

@keyframes animate{
    0%{
        border-left-color:#9849b0;
        /* color:pink; */
    }

    25%{
        border-top-color:#e16d9b;
    }

    50%{
        border-right-color:#9849b0;
        
    }

    75%{
        border-bottom-color:#e16d9b;
    }

    100%{
        border-color:#1e0338;
    }
}


section.reset-password div.form{
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background:#000000ed;
    
}

section.reset-password div.form .fa{
    color:#5d3b86;
}
section.reset-password div.form h3{
    font-size:15px;
    margin-bottom: 10px;
}

section.reset-password form div{
    display: flex;
    align-items: center;
    border:1px solid white;
    padding:0 10px;
    border-radius:5px;
    margin:5px 0 15px 0;
    position:relative;
    /* flex-direction: column; */
   
}

section.reset-password form div .eye{
    position: absolute;
    right:10px;
}

section.reset-password form div .eye:hover{
    cursor:pointer;
    transition: all 0.3s ease-in-out;
    color:#d764a3;
}

section.reset-password form div .fa-eye-slash {
    display: none;
}

section.reset-password form div input{
    background: transparent;
    outline: none;
    border:none;
    padding: 10px 15px;
    
    color: white;
}

section.reset-password div.form p{
    margin-top:10px;
    padding:10px 15px;
    background:#5d3b86;
    border-radius: 5px;
    font-size:15px;
}

section.reset-password div.form p:hover{
    cursor:pointer;
    transition: all 0.5s ease-in;
    background-color: #6f42a5;
}   