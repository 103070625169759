div.total-number-of-users{
    border: 3px solid white;
    border-radius:10px;
    height:100%;
    width:100%;
    display:flex;
    justify-content:space-around;
    text-align:center;
    align-items:center;
    padding:50px 10px;
}

div.total-number-of-users div.value{
    /* flex:2; */
    /* border:10px solid #f9a9c6; */
    border-radius: 50%;
    /* width:30px; */
    /* padding:25px 25px; */
    font-size:25px;
    margin-right:10px;
    color:#d2558f;
}

div.total-number-of-users >p{
    font-weight:bold;
}

@media (max-width:650px){
    div.total-number-of-users >p{
        font-size:15px;
    }
}