section.verify-email{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url('https://res.cloudinary.com/seyi-js/image/upload/v1606016777/get-started1_yhyuyd.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y:scroll;
}

section.verify-email > div{
    /* background:#3a1b58; */
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background:#000000ed;
}

section.verify-email > div h3{
    text-align: center;
}